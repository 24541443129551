import NavbarSideBar from '../../components/NavbarSidebar'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import SubFooter from '../../components/SubFooter'

const FindUs = () => {
    return (
        <>
        <NavbarSideBar/>
        <section className='pages'>
        <div className="flex flex-col w-[88vw] ml-[5vw] mb-[40vh] mt-[5vh]">
            <h1 className='caption-home1 text-[#4493f1] border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>A</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>About Us</p>
                <a href='about-us'><p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw]'>About Us</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Accolader</p>
                <a href='about-us'><p className='flex items-center text-[1.2rem] text-[#4493f1] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw]'>About Us <HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/> Accolades</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>B</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Banking & Finance</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/> Banking & Finance</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>C</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Capital Markets</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Capital Markets</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Competition & Antritrust</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Competition & Antritrust</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Corporate & Commercial</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Corporate & Commercial</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Corporate Real Estate</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Corporate Real Estate</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>E</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Employment, Health & Safety</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Employment, Health & Safety</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>F</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Find Us</p>
                <a href='find-us'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Find Us</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Fintech</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Fintech</p>
            </a>    
            </div>
            
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>I</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Intelectual Property</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Intelectual Property</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>international Arbitration</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>international Arbitration</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>L</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Litigation</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Litigation</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>M</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Maritime & Aviation</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Maritime & Aviation</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Mergers & acquisitions</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Mergers & acquisitions</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>P</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Partners</p>
                <a href='partners'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Partners</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Pathways</p>
                <a href='pathways'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Pathways</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Personal Data Protection Statement</p>
                <a href='personal-data-protection-statement'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Personal Data Protection Statement</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Perspectives</p>
                <a href='perspectives'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Perspectives</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Practice</p>
                <a href='practice'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice</p>
            </a>    
            </div><div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Publications</p>
                <a href='publications'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Publications</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>R</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Restructuring & Insolvency</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Restructuring & Insolvency</p>
            </a>    
            </div>
            <h1 className='caption-home1 text-[#4493f1]  border-[#f2f2f2] border-b-2 pb-[2vh] w-[35vw]'>T</h1>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Technology, Media & Telecommunications</p>
                <a href='#'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Practice<HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>Technology, Media & Telecommunications</p>
            </a>    
            </div>
            <div className='flex flex-row justify-between my-[3vh]'>
                <p className='text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[35vw]'>Term Of Use</p>
                <a href='term-of-use'><p className='flex items-center text-[1.2rem] border-[#f2f2f2] border-b-2 pb-[3vh] w-[50vw] text-[#4493f1]'>Term Of Use</p>
            </a>    
            </div>
        </div>
        </section>
        <SubFooter/>
        </>
    )
}

export default FindUs