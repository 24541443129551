import React from 'react';
import { useNavigate } from 'react-router-dom';
import shape1 from "../../assets/images/Ornament-69.svg";

import NavbarSidebar from '../../components/NavbarSidebar';
import Footer from '../../components/Footer';
import CardPractice from '../../components/CardPractice';
import { BsArrowRight } from 'react-icons/bs';
import Accolades from './components/accolades';

import news1 from '../../assets/images/news-1.jpg';
import news2 from '../../assets/images/news-2.jpg';
import news3 from '../../assets/images/news-3.jpg';
import news4 from '../../assets/images/news-4.jpg';

const Home = () => {
    const navigate = useNavigate();
    return (
        <>
        <NavbarSidebar />
        <section className='home'>
            <div className='absolute right-0 border-2 border-[#f2f2f2] bg-[#f2f2f2] w-[5vw] h-[440vh]'></div>
            <img src={shape1} className="w-[30vw] absolute"></img>
            <div className='flex flex-row place-self-end pr-[15vw]'>
                <ul className='practice'>
                    <li className='caption'>banking and finance</li>
                    <li className='caption'>capital markets</li>
                    <li className='caption'>corporate & antitrust</li>
                    <a href='/practice'>
                    <li className='flex flex-row text-[1rem] items-center font-normal mt-[7vh] hover:underline'><BsArrowRight className='w-[2vw]'/> ALL PRACTICE AREAS</li>
                    </a>
                    </ul>
                <ul className='practice'>
                    <li className='caption'>corporate & commercial</li>
                    <li className='caption'>corporate real estate</li>
                    <li className='caption'>employment, health & safety</li>
                </ul>
            </div>
            <div className='flex flex-col pl-[6vw] items-start justify-start pt-[10vh]'>
                <div className='flex flex-row'>
                <div className='w-[100%]'>
                    <div className='flex flex-col'>
                <ul className=''>
                    <h1 className='caption-home'>ABOUT US</h1>
                    <h3 className='caption-home-sub'>OUR STORIES</h3>
                    <p className='caption-home-sub2'>Founded in 1991, Soemadipradja & Taher has become one of Indonesia’s leading law firms.  Proud of their Indonesian heritage, our lawyers are well-grounded in not only legal expertise and knowledge, but also a deep understanding of Indonesian law, cultures and customs as well as the Indonesian commercial context.  This has given our lawyers a noted advantage in dealing with issues on behalf of our clients, who are often companies that are new to Indonesia and the Southeast Asia region in general. </p>
                    <a href='/about-us'>
                        <button className='btn2'>
                            Read More
                        </button>
                    </a>
                </ul>
                <Accolades/>
                </div>
                </div>
                
                <div>
                <ul className='flex flex-col ml-[7vw] mr-[6vw] mt-[20vh]'>
                    <div className='flex flex-row'>
                        <a href='/perspectives/details'>
                        <div className='caption-card box w-[18vw] h-[60vh] border-2 p-4 bg-white mr-[2vw] mt-[4vh] justify-center items-center'>
                            <p>08 April 2021 - Knowledge Highlights</p>
                            <img src={news1} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>Gojek fined Rp3.3 billion for late acquisition notification</p>
                        </div>
                        </a>
                        <div className='caption-card box w-[18vw] h-[60vh] border-2 p-4 mr-[2vw] mt-[4vh] justify-center items-center'>
                            <p>16 March 2021 - S&T News</p>
                            <img src={news2} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>S&T Partner Denia Isetianti Permata Recognised as ALB Indonesia Rising Star</p>
                        </div>
                    </div>
                    <div className='flex flex-row'>
                        <div className='caption-card box w-[18vw] h-[60vh] border-2 p-4 mr-[2vw] mt-[4vh] justify-center items-center'>
                            <p>07 March 2021 - S&T News</p>
                            <img src={news3} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>Against all odds: Soemadipradja & Taher helps secure a win for PT Astra Honda Motor in a competition complaint initiated by KPPU</p>
                        </div>
                        <div className='caption-card box w-[18vw] h-[60vh] border-2 p-4 mr-[2vw] mt-[4vh] justify-center items-center'>
                            <p>05 February 2021 - Knowledge Highlights</p>
                            <img src={news4} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>Guidelines on Mergers, Consolidations or Acquisitions Assessments Issued by Indonesia’s Competition Commission</p>
                        </div>
                    </div>
                    <div>
                    <a href='/perspectives'>
                    <button className='btn2'>MORE NEWS
                        </button>
                    </a>
                    </div>
                </ul>
                </div>
                </div>
                <div>
            <ul className='w-[85vw]'>
                    <h1 className='caption-home'>PRACTICE AREAS OVERVIEWS</h1>
                    <p className='caption-home-sub2'>Our range of practices are populated with seasoned practitioners ready to provide incisive expert knowledge and opinions on any particular matter. We offer a comprehensive suite of legal services delivered by a bench of lawyers with wide experience and deep expertise in their fields.</p>
                    <div>
    <section id="practice" className='mt-[5vh] justify-center items-center'>
        <div className="flex flex-row items-center justify-center mb-[5vh]">
          <CardPractice
            title='1'
            description='BANKING & FINANCE'
            onClick={() => navigate('/practice/banking-and-finance')}
            style='one' 
            />
          <CardPractice
            title='2'
            description='CAPITAL MARKETS'
            style='two'
          />
          <CardPractice
            title='3'
            description='COMPETITION & ANTITRUST'
            style='three'
          />
          <CardPractice
            title='4'
            description='CORPORATE & COMMERCIAL'
            style='four'
          />
        </div>
        <div className="flex flex-row mb-[5vh]"> 
          <CardPractice
            title='5'
            description='CORPORATE REAL ESTATE'
            style='five'
          />
          <CardPractice
            title='6'
            description='EMPLOYMENT, HEALTH & SAFETY'
            style='six'
          />
          <CardPractice
            title='7'
            description='ENERGY, INFRASTRUCTURE & PROJECTS'
            style='seven'
          />
          <CardPractice
            title='8'
            description='FINTECH'
            style='eight'
          />
        </div>
        <div className='flex justify-center items-center'>
        <a href='/practice'>
            <button className='btn2'>
                ALL PRACTICE AREAS
            </button>
                    </a>
        </div>
        </section>
            </div>
                </ul>
            </div>
            </div>
           </section>
           <Footer />
        </>
        
    );
}

export default Home;