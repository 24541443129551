import React from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Main from './pages/Main';
import Practice from './pages/Practice';
import Pathways from './pages/Pathways';
import Perspectivies from './pages/Perspectivies';
import Partners from './pages/Partners';
import AboutUs from './pages/AboutUs';
import SiteMap from './pages/SiteMap'
import TermOfUser from './pages/TermOfUser'
import PersonalData from './pages/PersonalData'
import Publication from './pages/Publication';
import BankingAndFinance from '../src/pages/Practice/components/bankingAndFinance'
import FindUs from './pages/FindUs'
import Internship from './pages/Internship'
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import InternForm from './pages/Internship/components/form';
import MailingList from './pages/MailingList';
import PartnersAH from './pages/Partners/components/AH';
import Details from './pages/Perspectivies/components/detail';


function App() {
  return (
    // <div className="App">
    //   <h1 className='Caption'>COMMITMENT, UNDERSTANDING, TRUST</h1>
    // </div>
    // <Main />
    // <Routes>
    //   <Route path='/' component={<Main />} />
    //   <Route path='/home' component={<Home />} />
    // </Routes>
    <div>
      <Routes>
        <Route path='/' element={<Main />}></Route>
        <Route path='/home' element={<Home />}></Route>
        <Route path='/practice' element={<Practice />}></Route>
        <Route path='/pathways' element={<Pathways />}></Route>
        <Route path='/perspectives' element={<Perspectivies />}></Route>
        <Route path='/partners' element={<Partners />}></Route>
        <Route path='/publication' element={<Publication/>}></Route>
        <Route path='/about-us' element={<AboutUs />}></Route>
        <Route path='/find-us' element={<FindUs />}></Route>
        <Route path='/site-map' element={<SiteMap />}></Route>
        <Route path='/term-of-user' element={<TermOfUser />}></Route>
        <Route path='/personal-data-protection-statement' element={<PersonalData />}></Route>
        <Route path='/practice/banking-and-finance' element={<BankingAndFinance />}></Route>
        <Route path='/pathways/internship' element={<Internship />}></Route>
        <Route path='/internship-application' element={<InternForm />}></Route>
        <Route path='/mailing-list' element={<MailingList />}></Route>
        <Route path='/partners/ahmad-djosan' element={<PartnersAH />}></Route>
        <Route path='/perspectives/details' element={<Details/>}></Route>
      </Routes>
    </div>
  );
}

export default App;
