import React from "react";
import { FiSearch } from "react-icons/fi";
import { BsTelephoneFill} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";

import logo from "../../assets/images/LOGO.png";
const Footer = () => {
    return (
        <section className="flex flex-row justify-between bg-white h-[50vh] px-[5vw] pt-[10vh]">
            <div className="">
                <a href="#" className="border-2 border-[#4493f1] px-[2.4vw] py-[1vh] rounded">
                    <button className="text-[#4493f1]">
                        SUBSCRIBE
                    </button>
                </a>
            </div>
            <div className="mr-[4vw]">
                <div className="pb-[5vh]">
                    <a href='/term-of-user' className="mr-[3vw]">TERM OF USE</a>
                    <a href='/personal-data-protection-statement' className="mr-[3vw]">PERSONAL DATA PROTECTION STATMENT</a>
                </div>
                <a href='site-map' className="mr-[3vw]">SITE MAP</a>
                <a href='find-us' className="mr-[3vw]">FIND US</a>
            </div>
            <div className="w-[14vw] ">
                <p className="mb-[1vh]">Jl. Jendral Sudirman No.28 Jakarta 10210 Indonesia</p>
                <p className="mb-[1vh] flex flex-row items-center"><BsTelephoneFill className='mr-[1vw]'/>(62-21) 50 999 879</p>
                <p className="mb-[1vh] flex flex-row items-center"><RiSendPlaneFill className='mr-[1vw]'/>(62-21) 50 999 879</p>
                <p className="mb-[1vh] flex flex-row items-center"><MdEmail className='mr-[1vw]'/>enquiries@soemath.com</p>
            </div>
                    <iframe width="250" height="250" id="gmap_canvas" src="https://maps.google.com/maps?q=Level%209,%20Jl.%20Jend.%20Sudirman%20No.28,%20RT.4/RW.2,%20Kuningan,%20Karet,%20Kecamatan%20Setiabudi,%20Kota%20Jakarta%20Selatan,%20Daerah%20Khusus%20Ibukota%20Jakarta%2010250,%20Indonesia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
        </section>
    );
}

export default Footer;