import React from 'react';
import { Link } from 'react-router-dom';
import shape1 from "../../assets/images/Ornament-69.svg";
import news from "../../assets/images/rectangle-45.svg";
import rectangle from "../../assets/images/Rectangle-39.svg";
import Ornament75 from "../../assets/images/Ornament-75.svg";
import Ornament76 from "../../assets/images/Ornament-76.svg";
import image3 from "../../assets/images/image-3.svg";

import NavbarSidebar from '../../components/NavbarSidebar';
import { BsArrowRight } from 'react-icons/bs';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { BsChevronDown } from 'react-icons/bs';
import { BsArrowRightShort } from 'react-icons/bs';
import CardPathways from '../../components/CardPathways';

const Internship = () => {
    return (
        <>
        <NavbarSidebar />
        <section className='items-start pathways '>
        <div className='flex flex-row pl-[5vw] justify-between pt-[5vh] '>
            <div className='flex flex-col'>
                    <h3 className='flex flex-row text-[1.1vw] text-[#123872]'><a href='/pathways'>PATHWAYS</a><BsArrowRightShort className='mx-[0.5vw] mt-[0.5vh]'/>INTERNSHIP</h3>
                <ul className='w-[95%]'>
                    <h1 className='caption-home1 text-[#4493f1]'>Internship</h1>
                </ul>
            </div>
        </div>
        <div className='flex flex-row justify-center'>
        
        </div>
        <div className='pl-[5vw]'>
            <h2 className='caption-pathways2 pb-[3vh]'>The Right Fit</h2>
            <div className='w-[90%]'>
                <p className='pb-[3vh] text-[#4493f1]'>The best way of finding out whether we are the right firm for you is to spend time with us. Many of our interns return as Trainees and qualify as lawyers with us.</p>
                <p className='pb-[3vh]'>An internship with Soemadipradja & Taher will provide you with a better understanding of our many specialised practice areas and will help you to decide which area of practice you are interested in.</p>
                <p className='pb-[3vh]'>Our internship program will allow you to experience life as a lawyer and to gain an insight into our firm's culture. You will have the opportunity to attend court hearings and client meetings, as well as to work on actual matters and undertake tasks such as preparing research memorandums and drafting legal documentation. You will also be invited to participate in training programmes organised for our lawyers so that you have an idea of the training you can expect to receive as a Trainee and an Associate.</p>
            </div>

            <a href='/internship-application' className=''>
                <button className='text-[#4493f1] btn5 my-[3vh]'>APPLY</button>
            </a>
        </div>
    </section>
        </>
        
    );
}

export default Internship;