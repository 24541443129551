import React from "react";
import { FiSearch } from "react-icons/fi";
import { GoThreeBars } from "react-icons/go";

import logo from "../../assets/images/LOGO.png";
import Sidebar from "../Sidebar";
const NavbarHome = () => {
    return (
        <>
        <section className="flex flex-row items-center h-[10vh]">
        <section className="flex flex-row justify-between items-center w-[100%] p-[5vw] py-[2vh]">
            <div className="logo">
                <a href="/home">
                    <img src={logo} alt="logo" className="w-[20vw]"/>
                </a>
            </div>
            <div className="flex flex-col justify-end items-end">
            <div className="flex flex-row">
                <div>
                    <ul className="nav-links ">
                        <li>
                            <div  className="pr-[16vw] flex opacity-50 items-start"><FiSearch className="icon mr-[1vw]" />Search</div>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul className="flex items-center nav-links2 mx-[1.2vw]">
                        <p >ID</p>
                    </ul>
                </div>
            </div>
            </div>

        </section>
        
    </section>
    <Sidebar/>
        </>
    );
}

export default NavbarHome;