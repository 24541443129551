import React from 'react';

import NavbarHome from '../../../components/NavbarHome';

import news1 from '../../../assets/images/news-1.jpg';
import news2 from '../../../assets/images/news-2.jpg';
import news3 from '../../../assets/images/news-3.jpg';
import news4 from '../../../assets/images/news-4.jpg';

import { BsArrowRightShort } from 'react-icons/bs';
import { MdOutlinePersonAddAlt } from 'react-icons/md';
import { TbDownload } from 'react-icons/tb';
import { FaRegPaperPlane } from 'react-icons/fa';

const Perspectives = () => {
    return (
        <>
        <NavbarHome />
        <section className='items-start home'>
        <div className='flex flex-col pl-[5vw] justify-between pt-[5vh] '>
            <h3 className='flex flex-row text-[1.1vw] text-[#123872]'><a href='/home'>HOME</a><BsArrowRightShort className='mx-[0.5vw] mt-[0.5vh]'/><a href='/perspectives'>PERSPECTIVE</a><BsArrowRightShort className='mx-[0.5vw] mt-[0.5vh]'/>KNOWLEDGE HIGHLIGHTS</h3>
                <ul className='w-[95%]'>
                    <h1 className='caption-home1'>Gojek fined Rp 3.3 billion for late acquisition notification</h1>
                </ul>
            <div className='perspectives flex-col pt-[30vh] pl-[4vw] text-white'>
            </div>
            <div>
            <ul className='w-[95%]'>
                <p className='caption-news font-bold my-[3vh]'>Backgorunds</p>
                <p className='caption-news my-[3vh]'>Over the past two decades, the Indonesian banking and financial services industries have constantly experienced change brought about by increased regulation in response to the Asian Financial Crisis in 1997, the emergence of new local and international players and the ongoing development of new technology in the field. Keeping pace with this sector and providing the most trusted and innovative response to its evolving needs demands strong resources and the requisite skills, knowledge, insight and experience.
                </p>
                <p className='caption-news font-bold  my-[3vh]'>Case Overview</p>
                <p className='caption-news my-[3vh]'>Lorem ipsum dolor sit amet consectetur. Tortor consequat massa tempor massa risus porta. Tempor sit iaculis condimentum euismod. Nibh adipiscing nisl et porttitor et. Lacinia nisl dui non scelerisque eget. Turpis arcu sit consectetur massa magna bibendum tincidunt sed. Porttitor orci pharetra at lorem porta erat. Sed amet ultrices hac nulla consectetur tempus nec. Pretium leo dictum habitant tellus in cursus id tellus. Sit id sed cursus a.
Posuere massa tempor in volutpat augue nunc et massa urna. Congue amet pulvinar justo arcu maecenas sit morbi elit. Nibh ultricies vitae non enim ante maecenas. Quam in tincidunt ipsum velit. Nunc accumsan eget tortor id augue volutpat et eu. Commodo in vulputate fusce eu eget potenti volutpat eu. Nullam cursus purus aliquam mauris sed integer amet.
Viverra risus suspendisse est nullam ut aliquet a. Aliquet vulputate nisi condimentum senectus augue sagittis ornare viverra mattis. Curabitur commodo nunc sagittis lectus quisque enim turpis. Sit nisi placerat a condimentum vulputate mattis. Tortor mattis est viverra quam eget fringilla odio ipsum. Adipiscing amet massa in a adipiscing semper.
Et nascetur integer enim dui vitae nibh. Placerat nulla amet velit enim risus volutpat tortor etiam. Imperdiet arcu pellentesque arcu dui faucibus a suspendisse et enim. Dictum mauris arcu augue consequat pellentesque. Cras facilisis tortor et sed turpis convallis. Urna faucibus pulvinar velit ultrices sollicitudin urna in arcu. Egestas sit lorem tellus tortor arcu tincidunt lacus. Diam congue tellus eget non aenean.</p>
                <p className='caption-news font-bold my-[3vh]'>Lesson learned</p>
                <p className='caption-news my-[3vh]'>Lorem ipsum dolor sit amet consectetur. Tortor consequat massa tempor massa risus porta. Tempor sit iaculis condimentum euismod. Nibh adipiscing nisl et porttitor et. Lacinia nisl dui non scelerisque eget. Turpis arcu sit consectetur massa magna bibendum tincidunt sed. Porttitor orci pharetra at lorem porta erat. Sed amet ultrices hac nulla consectetur tempus nec. Pretium leo dictum habitant tellus in cursus id tellus. Sit id sed cursus a.
Posuere massa tempor in volutpat augue nunc et massa urna. Congue amet pulvinar justo arcu maecenas sit morbi elit. Nibh ultricies vitae non enim ante maecenas. Quam in tincidunt ipsum velit. Nunc accumsan eget tortor id augue volutpat et eu. Commodo in vulputate fusce eu eget potenti volutpat eu. Nullam cursus purus aliquam mauris sed integer amet.
Viverra risus suspendisse est nullam ut aliquet a. Aliquet vulputate nisi condimentum senectus augue sagittis ornare viverra mattis. Curabitur commodo nunc sagittis lectus quisque enim turpis. Sit nisi placerat a condimentum vulputate mattis. Tortor mattis est viverra quam eget fringilla odio ipsum. Adipiscing amet massa in a adipiscing semper.
Et nascetur integer enim dui vitae nibh. Placerat nulla amet velit enim risus volutpat tortor etiam. Imperdiet arcu pellentesque arcu dui faucibus a suspendisse et enim. Dictum mauris arcu augue consequat pellentesque. Cras facilisis tortor et sed turpis convallis. Urna faucibus pulvinar velit ultrices sollicitudin urna in arcu. Egestas sit lorem tellus tortor arcu tincidunt lacus. Diam congue tellus eget non aenean.</p>
                <p className='caption-news font-bold my-[3vh]'>Further information</p>
                <p className='caption-news my-[3vh]'>Lorem ipsum dolor sit amet consectetur. Tortor consequat massa tempor massa risus porta. Tempor sit iaculis condimentum euismod. Nibh adipiscing nisl et porttitor et. Lacinia nisl dui non scelerisque eget. Turpis arcu sit consectetur massa magna bibendum tincidunt sed. Porttitor orci pharetra at lorem porta erat. Sed amet ultrices hac nulla consectetur tempus nec. Pretium leo dictum habitant tellus in cursus id tellus. Sit id sed cursus a.
Posuere massa tempor in volutpat augue nunc et massa urna. Congue amet pulvinar justo arcu maecenas sit morbi elit. Nibh ultricies vitae non enim ante maecenas. Quam in tincidunt ipsum velit. Nunc accumsan eget tortor id augue volutpat et eu. Commodo in vulputate fusce eu eget potenti volutpat eu. Nullam cursus purus aliquam mauris sed integer amet.
Viverra risus suspendisse est nullam ut aliquet a. Aliquet vulputate nisi condimentum senectus augue sagittis ornare viverra mattis. Curabitur commodo nunc sagittis lectus quisque enim turpis. Sit nisi placerat a condimentum vulputate mattis. Tortor mattis est viverra quam eget fringilla odio ipsum. Adipiscing amet massa in a adipiscing semper.
Et nascetur integer enim dui vitae nibh. Placerat nulla amet velit enim risus volutpat tortor etiam. Imperdiet arcu pellentesque arcu dui faucibus a suspendisse et enim. Dictum mauris arcu augue consequat pellentesque. Cras facilisis tortor et sed turpis convallis. Urna faucibus pulvinar velit ultrices sollicitudin urna in arcu. Egestas sit lorem tellus tortor arcu tincidunt lacus. Diam congue tellus eget non aenean.</p>
                
            </ul>
            </div>
            <div className='flex flex-row'>
                <div className='flex flex-row items-center'>
                    <button className='flex items-center justify-center caption-partners border-[0.1vw] rounded px-[1vw] py-[0.5vh] bg-[#4493f1] h-[6vh] text-white hover:bg-[#123872] mr-[1vw] mb-[3vh]'>
                    DOWNLOAD FULL ARTICLE<TbDownload  className='ml-[1vw]'/>
                    </button>
                </div>
                <div className='flex flex-row items-center'>
                    <button className='flex items-center justify-center caption-partners border-[0.1vw] rounded px-[1vw] py-[0.5vh] bg-[#4493f1] h-[6vh] text-white hover:bg-[#123872] mr-[1vw] mb-[3vh]'>
                    SHARE VIA LINKEDIN<FaRegPaperPlane className='ml-[1vw]'/>
                    </button>
                </div>
                <div className='flex flex-row items-center'>
                    <button className='flex items-center justify-center caption-partners border-[0.1vw] rounded px-[1vw] py-[0.5vh] bg-[#4493f1] h-[6vh] text-white hover:bg-[#123872] mr-[1vw] mb-[3vh]'>
                    SHARE VIA EMAIL<FaRegPaperPlane className='ml-[1vw]'/>
                    </button>
                </div>
            </div>
             <div className='flex flex-col'>
                <ul className='w-[95%]'>
                  <h1 className='caption-home-sub mt-[5vh]'>PERSPECTIVE</h1>
                </ul>
                <ul className='flex flex-row pb-[5vh] text-[#123872]'>
                  <a href='#'><button className='flex items-center caption-partners border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] hover:text-white mr-[1vw] mb-[3vh]'>ALL</button></a>
                  <a href='#'><button className='flex items-center caption-partners border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] hover:text-white mr-[1vw] mb-[3vh]'>S&T NEWS</button></a>
                  <a href='#'><button className='flex items-center caption-partners border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] hover:text-white mr-[1vw] mb-[3vh]'>KNOWLEDGE HIGHGHTS</button></a>
                </ul>
            </div>
            <div >
            <h1 className='caption-home-sub mt-[5vh]'>AUTHORED BY</h1>
            <div className='flex flex-row'>
            <div className='mr-[4vw]'>
                <p className='caption-partners'>Ahmad Djosan</p>
                <p className='caption-partners '>Indonesia</p>
                <p className='caption-partners '>(62-21) 50 999 879 (ext. 1922)</p>
                <p className='caption-partners '>ahmad_djosan@soemath.com</p>
            </div>
            <div className='mr-[4vw]'>
                <p className='caption-partners'>Ahmad Djosan</p>
                <p className='caption-partners '>Indonesia</p>
                <p className='caption-partners '>(62-21) 50 999 879 (ext. 1922)</p>
                <p className='caption-partners '>ahmad_djosan@soemath.com</p>
            </div>
            <div className='mr-[4vw]'>
                <p className='caption-partners'>Ahmad Djosan</p>
                <p className='caption-partners '>Indonesia</p>
                <p className='caption-partners '>(62-21) 50 999 879 (ext. 1922)</p>
                <p className='caption-partners '>ahmad_djosan@soemath.com</p>
            </div>
            </div>
                                
                
            </div>
        </div>
        <div>
                <ul className='flex flex-col ml-[5vw] mr-[6vw] mt-[10VHvh]'>
                <h1 className='caption-home1'>MORE</h1>
                    <div className='flex flex-row'>
                        <div className='flex flex-col caption-card box w-[20vw] h-[60vh] border-2 p-4 mr-[2vw] mt-[4vh] items-center'>
                            <p>08 April 2021 - Knowledge Highlights</p>
                            <img src={news1} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>Gojek fined Rp3.3 billion for late acquisition notification</p>
                        </div>
                        <div className='flex flex-col caption-card box w-[20vw] h-[60vh] border-2 p-4 mr-[2vw] mt-[4vh] items-center'>
                            <p>16 March 2021 - S&T News</p>
                            <img src={news2} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>S&T Partner Denia Isetianti Permata Recognised as ALB Indonesia Rising Star</p>
                        </div>
                        <div className='flex flex-col caption-card box w-[20vw] h-[60vh] border-2 p-4 mr-[2vw] mt-[4vh] items-center'>
                            <p>07 March 2021 - S&T News</p>
                            <img src={news3} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>Against all odds: Soemadipradja & Taher helps secure a win for PT Astra Honda Motor in a competition complaint initiated by KPPU</p>
                        </div>
                        <div className='flex flex-col caption-card box w-[20vw] h-[60vh] border-2 p-4 mr-[2vw] mt-[4vh] items-center'>
                            <p>05 February 2021 - Knowledge Highlights</p>
                            <img src={news4} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>Guidelines on Mergers, Consolidations or Acquisitions Assessments Issued by Indonesia’s Competition Commission</p>
                        </div>
                    </div>
                </ul>
                </div>
                

        {/* <div className='flex justify-center'>
        <div className='flex relative justify-center items-center px-[5vw]'>
            <img src={image3} className='w-[100vw]'></img>
            <h1>aaaaaaa</h1>
        </div>
        
        </div> */}
  
    </section>
        </>
        
    );
}

export default Perspectives;