// import React from "react";
// import { FiSearch } from "react-icons/fi";
import { GoThreeBars } from "react-icons/go";
import { BsChevronDown } from 'react-icons/bs';
import React from "react";

const Sidebar = () => {
const [isOpen, setIsOpen] = React.useState(false);
    return (
        <div className="absolute sidebar w-[5vw] top-0 right-0">
            <div className="">
                <button 
                onClick ={() => setIsOpen(!isOpen)}
                type="button"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                >
                    <div className="bg-[#f2f2f2] h-full">
            <GoThreeBars className="bg-[#f2f2f2] h-full icons2 mx-[1.5vw] pt-[4vh] pb-[2vh]" />

        </div>

                </button>
            </div>
            {isOpen && (<div className="origin-top-right z-50 absolute right-0 mr-[5vw] mt-[2vh] w-[45vw] h-[80vh] shadow-lg bg-white" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                <div className="py-3 text-[#4493f1] flex-row" role="none">
                    <div className="flex items-center justify-between w-[30vw] hover:underline">
                        <a href="/practice" className="flex items-center block px-2 py-[0.6vw] mb-[2vh] xt-small ml-[2vw]" >
                            PRACTICES
                        </a>
                        <BsChevronDown className='w-[2vw]'/>
                       
                    </div>
                    <div className="flex items-center justify-between w-[30vw] hover:underline">
                        <a href="/partners" className="flex items-center block px-2 py-[0.6vw]  mb-[2vh] all ml-[2vw]" >
                            PARTNERS
                            </a>
                            <BsChevronDown className='w-[2vw]'/>
                       
                    </div>
                    <div className="flex items-center justify-between w-[30vw] hover:underline">
                        <a href="/perspectives" className="flex items-center block px-2 py-[0.6vw] mb-[2vh] ml-[2vw]" >
                            PERSPECTIVES
                         </a>
                         <BsChevronDown className='w-[2vw]'/>
                       
                    </div>
                    <div className="flex items-center justify-between w-[30vw] hover:underline">
                        <a href="/pathways" className="flex items-center block px-2 py-[0.6vw] mb-[2vh] xt-small ml-[2vw]" >
                            PATHWAYS
                        </a>
                        <BsChevronDown className='w-[2vw]'/>
                    </div>
                    <div className="border-[0.1vw] w-[28vw] mb-[4vh] ml-[2vw] border-[#123872]"></div>
                        <div className="flex flex-row">
                        <div className="flex flex-col w-[20vw]">
                        <a href='/term-of-user' className="mr-[3vw] mb-[3vh] caption-home-sub3 text-[#123872] ml-[2vw] hover:underline">TERM OF USE</a>
                        <a href='/personal-data-protection-statement' className="mr-[3vw] mb-[3vh] caption-home-sub3 text-[#123872] ml-[2vw] hover:underline">PERSONAL DATA PROTECTION STATMENT</a>
                        <a href='site-map' className="mr-[3vw] mb-[3vh] caption-home-sub3 text-[#123872] ml-[2vw] hover:underline">SITE MAP</a>
                    </div>
                    
                    <a href='find-us' className="mr-[3vw] mb-[3vh] caption-home-sub3 text-[#123872] ml-[2vw] hover:underline">FIND US</a>
            </div>
            
                </div>
            </div>)}
        </div>
    )
}

export default Sidebar;