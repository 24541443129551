import React from 'react';
import NavbarSidebar from '../../../components/NavbarSidebar';

const InternForm = () => {
    return (
        <>
        <NavbarSidebar />
        <section className='items-start pl-[5vw] mt-[8vh]'>
        <div className='flex flex-row justify-center'>
        
        </div>
        <div className=''>
            <h2 className='caption-form text-[#4493f1] font-medium pb-[3vh]'>internship Apllication</h2>
            <h2 className='text-black font-normal pb-[3vh]'>Please complete each section of the application form in accordance with the instructions indicated.</h2>
            <div className='mt-[5vh]'>
                <h2 className='caption-form text-[#4493f1] font-medium'>personal information</h2>
            </div>    
        </div>

        <section className="pt-[2vh] pb-[5vw] flex items-center w-[95%]">
            <form className='flex flex-col flex-wrap justify-between w-[100%]'>
                <div className='flex flex-col'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1] font-medium'>title *</p>
                    <select className="border-[1px] block w-[100%] px-[0.5vw] py-[0.3vw] rounded" >
                        <option><p className='text-[#f2f2f2]'>Please Select</p></option>
                        <option>Mr.</option>
                        <option>Mrs.</option>
                        <option>Ms.</option>
                    </select>
                </div>
                <div className='flex flex-row justify-between'>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]'>first name *</p>
                    <input type="text" className="border-[1px] block w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>last name *</p>
                    <input type="text" className="border-[1px] block w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>nationality *</p>
                    <input type="text" className="border-[1px] block w-[100%] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                <div className='flex flex-row justify-between'>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>contact number *</p>
                    <input type="text" className="border-[1px] block w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>email address *</p>
                    <input type="email" className="border-[1px] block w-[44vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>KTP/passport number *</p>
                    <input type="text" className="border-[1px] block w-[100%] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                
                <div>
                <h2 className='text-black font-normal mt-[4vh] mb-[2vh]'>PLEASE INDICATE YOUR FIRST, SECOND AND THIRD CHOICE OF DAPARTEMENT WITHIN THE FIRM</h2>
                <div className='flex flex-row justify-between'>
                <div className='flex flex-col'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1] font-medium'>first choice *</p>
                    <select className="border-[1px] block w-[100%] px-[0.5vw] py-[0.3vw] rounded" >
                        <option>Please Select</option>
                        <option>Banking & Finance</option>
                        <option>Capital Markets</option>
                        <option>Competition & Antitrust</option>
                        <option>Corporate & Commercial</option>
                        <option>Corporate Real Estate</option>
                        <option>Employment, Health & Safety</option>
                        <option>Energy, Infrastructure & Projects</option>
                        <option>FinTech</option>
                        <option>Intellectual Property</option>
                        <option>International Arbitration</option>
                        <option>Litigation</option>
                        <option>Maritime & Aviation</option>
                        <option>Mergers & Acquisitions</option>
                        <option>Restructuring & Insolvency</option>
                        <option>Technology, Media & Telecommunication</option>
                        
                    </select>
                    </div>
                <div className='flex flex-col'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1] font-medium'>second choice *</p>
                    <select className="border-[1px] block w-[100%] px-[0.5vw] py-[0.3vw] rounded" >
                        <option>Please Select</option>
                        <option>Banking & Finance</option>
                        <option>Capital Markets</option>
                        <option>Competition & Antitrust</option>
                        <option>Corporate & Commercial</option>
                        <option>Corporate Real Estate</option>
                        <option>Employment, Health & Safety</option>
                        <option>Energy, Infrastructure & Projects</option>
                        <option>FinTech</option>
                        <option>Intellectual Property</option>
                        <option>International Arbitration</option>
                        <option>Litigation</option>
                        <option>Maritime & Aviation</option>
                        <option>Mergers & Acquisitions</option>
                        <option>Restructuring & Insolvency</option>
                        <option>Technology, Media & Telecommunication</option>
                    </select>
                    </div>
                <div className='flex flex-col'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  font-medium'>third choice *</p>
                    <select className="border-[1px] block w-[100%] px-[0.5vw] py-[0.3vw] rounded" >
                        <option>Please Select</option>
                        <option>Banking & Finance</option>
                        <option>Capital Markets</option>
                        <option>Competition & Antitrust</option>
                        <option>Corporate & Commercial</option>
                        <option>Corporate Real Estate</option>
                        <option>Employment, Health & Safety</option>
                        <option>Energy, Infrastructure & Projects</option>
                        <option>FinTech</option>
                        <option>Intellectual Property</option>
                        <option>International Arbitration</option>
                        <option>Litigation</option>
                        <option>Maritime & Aviation</option>
                        <option>Mergers & Acquisitions</option>
                        <option>Restructuring & Insolvency</option>
                        <option>Technology, Media & Telecommunication</option>
                        
                    </select>
                    </div>
                </div>
                </div>
                <div className='flex flex-col'>
                    <p className='caption-form mb-[2vh] mt-[8vh] text-[#4492f1] font-medium '>why join us</p>
                    <p className='text-black mb-[2vh] font-normal'>Max 1000 Words</p>
                    <textarea className="w-[100%] h-[40vh] border-[1px] px-[0.5vw] py-[0.3vw] rounded" ></textarea>
                </div>
                <div className='flex flex-row justify-between'>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>written language(s)</p>
                    <input type="text" className="border-[1px] block w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>spoken language(S)</p>
                    <input type="text" className="border-[1px] block w-[44vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                </div>
                <div className='flex flex-col'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1] font-medium '>DATE AND OUTCOME OF ANY PREVIOUS APPLICATION TO THE FIRM *</p>
                    <textarea className="w-[100%] h-[40vh] border-[1px] px-[0.5vw] py-[0.3vw] rounded" ></textarea>
                </div>
            </form>
        </section>
        <a>
                <button className='mb-[10vh] px-[1vw] py-[1vh] bg-[#4493f1] rounded hover:bg-[#123872]'>
                    <p className='text-white font-normal'>Next</p>
                </button>
            </a>

    </section>
        </>
        
    );
}

export default InternForm;