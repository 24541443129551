import NavbarSideBar from '../../components/NavbarSidebar'
import SubFooter from '../../components/SubFooter'

const PersonalData = () => {
    return (
        <>
        <NavbarSideBar/>
        <section className='pages pb-[20vh]'>
            <div className='flex flex-row pl-[5vw] justify-between pt-[5vh] '>
                <div className='flex flex-col'>
                    <ul className='w-[95%]'>
                        <h1 className='caption-home1 text-[#4493f1]'>Personal Data Protection Statement</h1>
                        <p className='caption-home-sub3 mb-[3vh] '>This is the personal data protection statement of Soemadipradja & Taher which we may update from time to time. Provision of personal data is not required to browse our website.</p>
                        <p className='caption-home-sub3 mb-[3vh]' >COLLECTION, USE AND DISCLOSURE OF YOUR PERSONAL DATA <br></br>
                            The personal data we collect from you will be used in the following ways:
                        </p>
                        <p className='caption-home-sub3 mb-[3vh] '>Provision of services to you: Where you have engaged us to provide services to you, your personal data may be collected, used and disclosed by Soemadipradja & Taher and its affiliates, service providers and agents to enable Soemadipradja & Taher and its affiliates, service providers and agents to properly provide services to or for you, and to enable Soemadipradja & Taher and its affiliates, services providers and agents to comply with applicable laws, regulations, industry codes and guidelines, and for internal audit.</p>
                        <p className='caption-home-sub3 mb-[3vh] '>Soemadipradja & Taher’s publications: Where you have requested to be on our mailing list to receive Soemadipradja & Taher's complimentary electronic publications (including bulletins, updates, alerts, reviews, invitations and announcements) via our electronic form or otherwise, we will use your personal data to send such publications to you.
                           <br></br> Cookies: We use cookies for web analytics. Disabling cookies in your browser will not affect your user experience.</p>
                    </ul>
                </div>
            </div>
        </section>
        <SubFooter/>
        </>
    )
}

export default PersonalData