import React from 'react';
import { Icon } from '@iconify/react';
// import personAddOutlineRounded from '@iconify/icons-material-symbols/person-add-outline-rounded';
import NavbarSidebar from '../../../components/NavbarSidebar';
import Footer from '../../../components/Footer';
import { FiSearch } from 'react-icons/fi';
import { IoMailOutline } from 'react-icons/io5';
import { BsTelephone } from 'react-icons/bs';
import { MdOutlinePersonAddAlt } from 'react-icons/md';
import { TbDownload } from 'react-icons/tb';

import AH from '../../../assets/images/Partners/AH.jpg'

const PartnersAH = () => {
    return (
        <>
        <NavbarSidebar />
        <section className='pages'>
            <div className='ml-[5vw] mb-[15vh]'>
                <ul className='flex flex-col mt-[5vh]'>
                    <div className='flex flex-row'>
                        <div className='flex flex-row caption-card w-[88vw] h-[38vh] border-2 bg-white mr-[1vw] mt-[5vh]'>
                            <img src={AH} className='w-[20vw] h-full'></img>
                            <div className='pl-[2vw] pt-[5vh] justify-start'>
                                <p className='caption-partners'>Ahmad Djosan</p>
                                <p className='caption-partners '>Indonesia</p>
                                <p className='caption-partners flex items-center '><BsTelephone className='mr-[1vw]'/>(62-21) 50 999 879 (ext. 1922)</p>
                                <p className='caption-partners flex items-center '><IoMailOutline className='mr-[1vw]'/>ahmad_djosan@soemath.com</p>
                                <div className='flex flex-row'>
                                    <div className='flex flex-row items-center'>
                                        <button className='flex items-center caption-partners w-[10vw] border-[0.1vw] rounded px-[0.5vw] py-[0.5vh] bg-[#4493f1] hover:text-white hover:bg-[#123872] mr-[1vw] mb-[3vh]'>
                                        <MdOutlinePersonAddAlt className='mr-[1vw]'/>Add contact
                                        </button>
                                    </div>
                                    <div className='flex flex-row items-center'>
                                        <button className='flex items-center caption-partners w-[10vw] border-[0.1vw] rounded px-[0.5vw] py-[0.5vh] bg-[#4493f1] hover:text-white hover:bg-[#123872] mr-[1vw] mb-[3vh]'>
                                        <TbDownload className='mr-[1vw]'/>Download PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className='flex flex-row  caption-partners2 mt-[6vh] text-black uppercase'>
                            <a href='#profile'><li className='pr-[3vw] hover:underline'>profile</li></a>
                            <a href='#work-highlights'><li className='pr-[3vw] hover:underline '>work highlights</li></a>
                            <a href='#education'><li className='pr-[3vw] hover:underline '>education</li></a>
                            <a href='#admission'><li className='pr-[3vw] hover:underline '>admission</li></a>
                            <a href='#key-practice'><li className='pr-[3vw] hover:underline '>key practice</li></a>
                            <a href='#publication'><li className='pr-[3vw] hover:underline '>publication</li></a>
                        </ul>
                    </div>
                    <div className='border-[0.1vw] border-black w-[88vw]'></div>
                    <div className='w-[88vw]'>
                    <div id='profile'>
                        <p className='caption-partners2 uppercase text-[#4493f1] font-bold mt-[5vh] uppercase'>Profile</p>
                        <p className='caption-partners'>Djosan has extensive experience working in the land transaction and land dispute sectors. He has particular focus on land acquisitions, land relinquishment, overlapping of land rights and business transactions related to land, customary law and community matters, property, environment and forestry issues, in regions across Indonesia, particularly in the Kalimantan, Papua, Sulawesi and Sumatera provinces.
                            Djosan also specialises in general litigation, alternative dispute resolution, bankruptcy and suspension of payment, tax, employment and intellectual property rights.
                            Djosan is a member of the Indonesian Advocates Association (PERADI).</p>
                    </div>
                    <div id='work-highlights'>
                        <p className='caption-partners2 uppercase text-[#4493f1] font-bold mt-[5vh] uppercase'>work highlights</p>
                        <ul className='ml-[2vw]'>
                            <li type="disc" className='pb-[2vh]'>Advised the Indonesian office of a global energy business company that provides fuel for transport, energy for heat and light, power for industry, lubricants and petrochemical products, on several lawsuits relating to overlapping land certificates before the State Administrative Court and the Supreme Court of Indonesia.</li>
                            <li type="disc" className='pb-[2vh]'>Advised the Indonesian subsidiaries of a prominent United States- based gold mining company, on civil and criminal cases in respect of land in the Contract of Work areas, environmental disputes, and other corporate legal issues with third parties.</li>
                        </ul>
                    </div>
                    <div id='education'>
                        <p className='caption-partners2 uppercase text-[#4493f1] font-bold mt-[5vh] uppercase'>Education</p>
                        <p className='caption-partners'>Sarjana Hukum, Universitas Andalas</p>
                    </div>
                    <div id='admission'>
                        <p className='caption-partners2 uppercase text-[#4493f1] font-bold mt-[5vh] uppercase'>Admission</p>
                        <p className='caption-partners'>Indonesia Bar (1999)</p>
                    </div>
                    <div id='key-practice'>
                        <p className='caption-partners2 uppercase text-[#4493f1] font-bold mt-[5vh] uppercase'>key practice</p>
                        <ul className='ml-[2vw]'>
                            <li type="disc" className='pb-[2vh]'>Corporate Real Estate</li>
                            <li type="disc" className='pb-[2vh]'>intellectual Property</li>
                            <li type="disc" className='pb-[2vh]'>Restructuring & Insolvency</li>
                        </ul>
                    </div>
                    <div id='publication'>
                        <p className='caption-partners2 uppercase text-[#4493f1] font-bold mt-[5vh] uppercase'>work highlights</p>
                        <ul className='ml-[2vw]'>
                            <li type="disc" className='pb-[2vh]'>Lorem ipsum dotsiamet</li>
                            <li type="disc" className='pb-[2vh]'>Lorem ipsum dotsiamet</li>
                        </ul>
                    </div>
                    </div>
                </ul>
                </div>
            
           </section>
           <Footer />
        </>
        
    );
}

export default PartnersAH;