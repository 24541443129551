import React from 'react';
import { Link } from 'react-router-dom';
import shape1 from "../../assets/images/Ornament-69.svg";
import news from "../../assets/images/rectangle-45.svg";
import rectangle from "../../assets/images/Rectangle-39.svg";
import Ornament75 from "../../assets/images/Ornament-75.svg";
import Ornament76 from "../../assets/images/Ornament-76.svg";
import image3 from "../../assets/images/image-3.svg";

import NavbarHome from '../../components/NavbarHome';
import { BsArrowRight } from 'react-icons/bs';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { BsChevronDown } from 'react-icons/bs';
import { BsArrowRightShort } from 'react-icons/bs';
import CardPathways from '../../components/CardPathways';
import SubFooter from '../../components/SubFooter';

const Home = () => {
    return (
        <>
        <NavbarHome />
        <section className='items-start pages pb-[15vh]'>
        <div className='flex justify-center items-center pt-[10vh] pl-[5vw] pr-[10vw]'>
            <img src={image3} className='relative w-[100vw]'></img>
        </div>
        <div className='flex flex-row pl-[5vw] justify-between pt-[5vh] '>
            <div className='flex flex-col'>
                <h1 className='caption-home1 text-[#4493f1]'>About Us</h1>
                <p className='caption-home-sub3 w-[86%]'>Founded in 1991, Soemadipradja & Taher has become one of Indonesia’s leading law firms. Proud of their Indonesian heritage, our lawyers are well-grounded in not only legal expertise and knowledge, but also a deep understanding of Indonesian law, cultures and customs as well as the Indonesian commercial context. This has given our lawyers a noted advantage in dealing with issues on behalf of our clients, who are often companies that are new to Indonesia and the Southeast Asia region in general.<br></br>
                    Our lawyers have a wealth of experience representing Indonesian, foreign and multinational clients, and have the ability to look beyond traditional approaches to produce creative and practical solutions for a wide range of legal issues. Through this approach, our lawyers have gained a deep understanding of our clients’ businesses, industries and corporate goals, ensuring that the firm provides the most appropriate legal solutions for the unique circumstances faced by each of our clients.<br></br>
                    Having formed an association with the Allen & Gledhill network in 2019, we have expanded our horizons and network throughout Southeast Asia, and are now able to assist an ever-widening base of clients seeking to take on the many business opportunities offered by the region. We also maintain relationships with a number of leading international and regional law firms.<br></br>
                    Soemadipradja & Taher and its partners and lawyers are consistently ranked in the top tiers of legal publications and directories, and we are particularly proud of our litigation and dispute resolution group, which has been ranked in Tier 1 in several legal directories for the past several years.<br></br>
                    In Indonesia’s rapidly evolving and changing legal environment in Indonesia, Soemadipradja & Taher and its teams of lawyers, in association with the Allen & Gledhill network, look forward to helping its clients navigate the intricacies of Indonesian law, culture and commerce, applying the highest ethical and professional standards in its practice, and steadfastly providing a stable presence for its diverse array of clients.</p>
                
            </div>
        </div>
    </section>
    <SubFooter/>
        </>
        
    );
}

export default Home;