import React from 'react';
import { Link } from 'react-router-dom';
import shape1 from "../../assets/images/Ornament-69.svg";
import news from "../../assets/images/rectangle-45.svg";
import rectangle from "../../assets/images/Rectangle-39.svg";
import Ornament75 from "../../assets/images/Ornament-75.svg";
import Ornament76 from "../../assets/images/Ornament-76.svg";
import image3 from "../../assets/images/image-3.svg";

import NavbarSidebar from '../../components/NavbarSidebar';
import { BsArrowRight } from 'react-icons/bs';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { BsChevronDown } from 'react-icons/bs';
import { BsArrowRightShort } from 'react-icons/bs';
import CardPathways from '../../components/CardPathways';
import SubFooter from '../../components/SubFooter';

const Home = () => {
    return (
        <>
        <NavbarSidebar />
        <section className='pages pb-[10vh]'>
        <div className='flex flex-row pl-[5vw] justify-between pt-[5vh] '>
            <div className='flex flex-col'>
                    <h3 className='flex flex-row text-[1.1vw] text-[#123872]'><a href='/home'>HOME</a><BsArrowRightShort className='mx-[0.5vw] mt-[0.5vh]'/>PATHWAYS</h3>
                <ul className='w-[95%]'>
                    <h1 className='caption-home1 text-[#4493f1]'>Pathways</h1>
                </ul>
            </div>
        </div>
        <div className='flex flex-row justify-center'>
        <div>
            <img src={Ornament75} className='absolute left-0 w-[8vw]'></img>
            <img src={Ornament76} className='absolute right-0 bottom-[-60vh] w-[15vw]'></img>
        </div>
        <div className='justify-center items-center px-[5vw]'>
            <img src={image3} className='relative w-[100vw]'></img>
        </div>
        
        </div>
        <div className='pl-[5vw]'>
            <h1 className='caption-pathways py-[5vh]'>Work With Us</h1>
            <h2 className='caption-pathways2 pb-[3vh]'>Careers & Opportunities</h2>
            <div className='w-[90%]'>
                <p className='pb-[3vh]'>Soemadipradja & Taher recruits the best talent available, and is always looking to find and retain lawyers, graduates and professionals with the skills, values, confidence and capabilities required to deliver professional and service excellence.</p>
                <p className='pb-[3vh]'>If you are interested in exploring the possibility of a career with Soemadipradja & Taher, please find out more below.</p>
            </div>
        </div>

        <section id="our-platforms" className='pl-[5vw] mt-[2vh] justify-center'>
        <div className="flex flex-row justify-start">
          <CardPathways
            title='Internship'
            description='Join us to experience life as a lawyer during final year of studying law.'
            style='' 
            onClick={() => {window.location.href = '/pathways/internship'}}
            />
          <CardPathways
            title='Quallified Lawyears'
            description='Join our legal team to work alongside ecperienced lawyers '
            style=''
          />
        </div>
        <div className="flex flex-row"> 
          <CardPathways
            title='Business Services'
            description='Join our skilled staff who keeps our legal team at the height of their game'
            style=''
          />
        </div>
      </section>
    </section>
    <SubFooter/>
        </>
        
    );
}

export default Home;