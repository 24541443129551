import NavbarSideBar from '../../components/NavbarSidebar'
import { BsTelephoneFill } from 'react-icons/bs'
import { MdEmail } from 'react-icons/md'
import { RiSendPlaneFill } from 'react-icons/ri'
import SubFooter from '../../components/SubFooter'

const FindUs = () => {
    return (
        <>
        <NavbarSideBar/>
        <section className='pages'>
        <div className="w-[14vw] ml-[5vw] mb-[50vh] mt-[5vh]">
        <iframe width="400" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=Level%209,%20Jl.%20Jend.%20Sudirman%20No.28,%20RT.4/RW.2,%20Kuningan,%20Karet,%20Kecamatan%20Setiabudi,%20Kota%20Jakarta%20Selatan,%20Daerah%20Khusus%20Ibukota%20Jakarta%2010250,%20Indonesia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
            <div className='mt-[8vh]'>
            <p className="mb-[1vh]">Wisma GBK, level 9</p>
                <p className="mb-[1vh]">Jl. Jendral Sudirman No.28 Jakarta 10210 Indonesia</p>
                <p className="mb-[1vh] flex flex-row items-center"><BsTelephoneFill className='mr-[1vw]'/>(62-21) 50 999 879</p>
                <p className="mb-[1vh] flex flex-row items-center"><RiSendPlaneFill className='mr-[1vw]'/>(62-21) 50 999 879</p>
                <p className="mb-[1vh] flex flex-row items-center"><MdEmail className='mr-[1vw]'/>enquiries@soemath.com</p>
            </div>
            </div>
        </section>
        <SubFooter/>
        </>
    )
}

export default FindUs