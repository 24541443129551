import React from 'react';

import NavbarHome from '../../components/NavbarHome';

import news1 from '../../assets/images/news-1.jpg';
import news2 from '../../assets/images/news-2.jpg';
import news3 from '../../assets/images/news-3.jpg';
import news4 from '../../assets/images/news-4.jpg';
import SubFooter from '../../components/SubFooter';

const Perspectives = () => {
    const data = [
        {
            id:1,
            date: "08 April 2021",
            title: "Gojek fined rp 3.3 billion for late acquisition notification",
            category: "Knowledge Highlights",
            img: news1,
        },
        {
            id:2,
            date: "16 Maret 2021",
            title: "S&T Partner Denia Isetianti Permata Recognised as ALB Indonesia Rising Star",
            category: "S&T News",
            img: news2,
        },
        {
            id:3,
            date: "7 Maret 2021",
            title: "Against all odds: Soemadipradja & Taher helps secure a win for PT Astra Honda Motor in a competition complaint initiated by KPPU",
            category: "S&T News",
            img: news3,
        },
        {
            id:2,
            date: "05 Februari 2021",
            title: "Guidelines on Mergers, Consolidations or Acquisitions Assessments Issued by Indonesia’s Competition Commission",
            category: "Knowledge Highlights",
            img: news4,
        },
        
    ]

    const [filter, setFilter] = React.useState('all');
    const [filteredData, setFilteredData] = React.useState([]);

    React.useEffect(() => {
        filter === 'all'
            ? setFilteredData(data)
            : setFilteredData(data.filter((item) => item.category === filter));
    }, [filter]);

    
    return (
        <>
        <NavbarHome />
        <section className='pages pb-[10vh]'>
        <div className='flex flex-col pl-[5vw] justify-between pt-[5vh] '>
            <div className='flex flex-col'>
                <ul className='flex flex-row justify-between'>
                  <h1 className='caption-home1 text-[#4493f1]'>PERSPECTIVE</h1>
                  <select className="border-b-[1px] block caption-partners2 w-[15vw] h-[5vh] px-[0.5vw] py-[0.3vw] rounded" >
                    <option ><a href='/perspectives'>PERSPECTIVES</a></option>
                    <option ><a href='/publication'>PUBLICATION</a></option>
                </select>
                </ul>
                <ul className='flex flex-row pb-[5vh] text-[#123872]'>
                  
                    <button  
                        onClick={() => setFilter('all')}
                        className='flex items-center caption-partners border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] focus:bg-[#4493f1] focus:text-white hover:text-white mr-[1vw] mb-[3vh]'>ALL</button>
                    <button 
                    onClick={() => setFilter('Knowledge Highlights')}
                    className='flex items-center caption-partners border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] focus:bg-[#4493f1] focus:text-white hover:text-white mr-[1vw] mb-[3vh]'>S&T NEWS</button>
                  <button 
                  onClick={() => setFilter('S&T News')}
                  className='flex items-center caption-partners border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] focus:bg-[#4493f1] focus:text-white hover:text-white mr-[1vw] mb-[3vh]'>KNOWLEDGE HIGHGHTS</button>
                </ul>
            </div>
            <div className='perspectives flex-col pt-[30vh] pl-[4vw] text-white'>
              <h1>KNOWLEDGE HIGHLIGHTS | 07MARCH 2020</h1>
              <p className='py-[5vh]'>Gojek fined rp 3.3 billion for late acquisition notification</p>
              <a href='/perspectives/details'>
                        <button className='btn3 hover:bg-white hover:text-black rounded'>
                            Read More
                        </button>
                    </a>
            </div>
        </div>
        <div className='flex flex-row ml-[5vw] mt-[8vh]'>
            {
                filteredData.map((item) => {
                    return (
                        <div className='flex flex-row'>
                            <div className='flex flex-col caption-card box w-[20vw] h-[60vh] border-2 p-4 mr-[2vw] mt-[4vh] items-center'>
                            <p>{item.date} - {item.category}</p>
                            <img src={item.img} className='w-[15vw] py-[2vh]'></img>
                            <p className=''>{item.title}</p>
                        </div>
                        </div>
                    )
                })
            }
            </div>
                

        {/* <div className='flex justify-center'>
        <div className='flex relative justify-center items-center px-[5vw]'>
            <img src={image3} className='w-[100vw]'></img>
            <h1>aaaaaaa</h1>
        </div>
        
        </div> */}
  
    </section>
    <SubFooter/>
        </>
        
    );
}

export default Perspectives;