import React from "react";

const SubFooter = () => {
    return (
        <section className="flex flex-row items-center justify-between bg-[#4A4F57] h-[10vh] px-[5vw]">
            <div className="mr-[4vw] text-white">
                    <a href='/term-of-user' className="mr-[3vw] hover:underline">TERM OF USE</a>
                    <a href='/personal-data-protection-statement' className="mr-[3vw] hover:underline">PERSONAL DATA PROTECTION STATMENT</a>
                <a href='site-map' className="mr-[3vw] hover:underline">SITE MAP</a>
                <a href='find-us' className="mr-[3vw] hover:underline">FIND US</a>
            </div>
        </section>
    );
}

export default SubFooter;