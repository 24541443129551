import React from 'react';

import NavbarSidebar from '../../components/NavbarSidebar';
import SubFooter from '../../components/SubFooter';

import { FiSearch } from 'react-icons/fi';
import { IoMailOutline } from 'react-icons/io5';
import { BsTelephone } from 'react-icons/bs';

import AH from '../../assets/images/Partners/AH.jpg'
import ADS from '../../assets/images/Partners/ADS.jpg'
import DIP from '../../assets/images/Partners/DIP.jpg'
import EA from '../../assets/images/Partners/EA.jpg'
import EHT from '../../assets/images/Partners/EHT.jpg'
import HF from '../../assets/images/Partners/HF.jpg'
import NN from '../../assets/images/Partners/NN.jpg'
import RAD from '../../assets/images/Partners/RAD.jpg'
import RE from '../../assets/images/Partners/RE.jpg'
import RS from '../../assets/images/Partners/RS.jpg'
import OJM from '../../assets/images/Counsel/OJM.jpg'
import RR from '../../assets/images/Counsel/RR.jpg'
import ABP from '../../assets/images/Counsel/ABP.jpg'

const Partners = () => {
    const data = [
        {
            id:1,
            name:"Ahmad Djosan",
            phone:"(62-21) 50 999 879 (ext. 1922)",
            country:"Indonesia",
            email:"ahmad_djosan@soemath.com",
            images:AH,
            path:"/partners/ahmad-djosan",
            practice: ["Corporate Real Estate", "Intelectual Property", "Restructuring & Insolvency"]
        },
        {
            id:2,
            name:"Ardian Deny Sidharta",
            phone:"(62-21) 50 999 879 (ext. 1925)",
            country:"Indonesia",
            email:"deny-sidharta@soemath.com",
            images:ADS,
            practice: ["Competition & Antitrust", "Corporate Real Estate", "Intelectual Property", "Energy, Infrastructure & Project"]
        },
        {
            id:3,
            name:"Denia Isetianti Permata",
            phone:"(62-21) 50 999 879 (ext. 1919)",
            country:"Indonesia",
            email:"denia_isetianti@soemath.com",
            images:DIP,
            practice: ["Banking & Finance", "Capital Markets", "Corporate & Commercial", "Fintech", "Merger & Acquisitions", "Technology, Media & Telecomunications"]
        },
        {
            id:4,
            name:"Emalia Achmadi",
            phone:"(62-21) 50 999 879 (ext. 1906)",
            country:"Indonesia",
            email:"emalia_achmadi@soemath.com",
            images:EA,
            practice: ["Banking & Finance", "Capital Markets", "Corporate & Commercial", "Fintech", "Merger & Acquisitions"]
        },
        {
            id:5,
            name:"Erie Hotman Tobing",
            phone:"(62-21) 50 999 879 (ext. 1916)",
            country:"Indonesia",
            email:"erie_tobing@soemath.com",
            images:EHT,
            practice: ["International Arbitration", "Litigation", "Maritime & Aviation", "Restructuring & Insolvency"]
        },
        {
            id:6,
            name:"Hafzan Taher",
            phone:"(62-21) 50 999 879 (ext. 1920)",
            country:"Indonesia",
            email:"hafzan_taher@soemath.com",
            images:HF,
            practice: 'Litigation'
        },
        {
            id:7,
            name:"Nira Sari Nazarudin",
            phone:"(62-21) 50 999 879 (ext. 1919)",
            country:"Indonesia",
            email:"nira_sn@soemath.com",
            images:NN,
            practice: ["International Arbitration", "Litigation", "Maritime & Aviation", "Restructuring & Insolvency"]
        },
        {
            id:8,
            name:"Rahmat S. S. Soemadipradja",
            phone:"(62-21) 50 999 879 (ext. 1913)",
            country:"Indonesia",
            email:"rahmat_s@soemath.com",
            images:RS,
            practice: ["Corporate & Commercial", "Energy, Infrastructure & Project", "International Arbitration", "Litigation", "Maritime & Aviation", "Mergers & Acquisitions", "Technology, Media & Telecomunications"]
        },
        {
            id:9,
            name:"Retno Muljosantoso",
            phone:"(62-21) 50 999 879 (ext. 1901)",
            country:"Indonesia",
            email:"r_muljosantoso@soemath.com",
            images:RAD,
            practice: ["Banking & Finance", "Capital Markets", "Corporate & Commercial", "Employment, Health, & Safety", "Merger & Acquisitions"]
        },
        {
            id:10,
            name:"Aris Budi Prasetiyo",
            phone:"(62-21) 50 999 879 (ext. 1927)",
            country:"Indonesia",
            email:"aris_prasetiyo@soemath.com",
            images:ABP,
            practice: ["Capital Markets, Corporate & Commercial", "Maritime & Aviation"]
        },
        {
            id:11,
            name:"Oene Marseille",
            phone:"(62-21) 50 999 879 (ext. 1923)",
            country:"Indonesia",
            email:"oene_marseille@soemath.com",
            images:OJM,
            practice: ["Banking & Finance", "Corporate & Commercial", "Fintech", "Merger & Acquisitions"]
        },
        {
            id:12,
            name:"Robert Reid",
            phone:"(62-21) 50 999 879 (ext. 1904)",
            country:"Indonesia",
            email:"robert_reid@soemath.com",
            images:RR,
            practice: ["Banking & Finance", "Corporate & Commercial", "Employment, Health, & Safety", "Fintech", "Merger & Acquisitions"]
        }, 
        {
            id:13,
            name:"Romi Emirat",
            phone:"(62-21) 50 999 879 (ext. 1924)",
            country:"Indonesia",
            email:"romi_emirat@soemath.com",
            images:RE,
            practice: ["Practice", "Litigation", "Restructuring & Insolvency"]
        }
    ]
    const [searchTerm, setSearchTerm] = React.useState('');

    
    const [selectOption, setSelectOption] = React.useState('All Practice');

    const handleChange = (event) => {
        const setSelectOption = event.target.value;
        const filteredData = data.filter((item) => {
            return item.practice.includes(setSelectOption);
        })
       setSelectOption(filteredData);

    }
    
    return (
        <>
        <NavbarSidebar />
        <section className='pages pl-[5vw] pb-[55vh]'>
            <div className='mt-[10vh]'>
                {/* <div className="flex flex-col search w-[87vw]">
                        <div className="pr-[16vw] flex opacity-50 items-start"><FiSearch className="icon mr-[1vw]" />Search Name</div>        
                </div> */}
                <div className="flex flex-col w-[87vw]">
                    <input className="search pr-[16vw] flex items-start" onChange={event =>{setSearchTerm(event.target.value)}} prefix={<FiSearch />} placeholder="Search Name"></input>  
                </div>
                <ul>
                <select 
                value = {selectOption}
                onChange = {(e) => {handleChange(e)}}
                className="border-b-[1px] caption-partner2 block w-[15vw] h-[5vh] mt-[5vh] px-[0.5vw] py-[0.5vw] rounded" >
                        <option value='Banking & Finance'>Banking & Finance</option>
                        <option value='Capital Markets'>Capital Markets</option>
                        <option value='Competition & Antitrust'>Competition & Antitrust</option>
                        <option value='Corporate & Commercial'>Corporate & Commercial</option>
                        <option value='Corporate Real Estate'>Corporate Real Estate</option>
                        <option value='Employment, Health & Safety'>Employment, Health & Safety</option>
                        <option value='Energy, Infrastructure & Projects'>Energy, Infrastructure & Projects</option>
                        <option value='FinTech'>FinTech</option>
                        <option value='Intellectual Property'>Intellectual Property</option>
                        <option value='International Arbitration'>International Arbitration</option>
                        <option value='Litigation'>Litigation</option>
                        <option value='Maritime & Aviation'>Maritime & Aviation</option>
                        <option value='Mergers & Acquisitions'>Mergers & Acquisitions</option>
                        <option value='Restructuring & Insolvency'>Restructuring & Insolvency</option>
                        <option value='Technology, Media & Telecommunication'>Technology, Media & Telecommunication</option>
                    </select>
                </ul>
            </div>
            <div className='flex flex-row'>
            <div className=''>
            {
                data.map((val, items ) => {
                    if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                        if (val.id % 2 == 1) {
                        return (
                            <div className='flex flex-row'>
                                <a href={val.path}>
                                <div key={items} className='flex flex-row caption-card box1 cursor-pointer w-[43vw] h-[38vh] border-[0.1vw] bg-white mr-[1vw] mt-[5vh]'>
                                <img src={val.images} className='w-[20vw] h-full'></img>
                                <div className='pl-[2vw] pt-[5vh] justify-start'>
                                    <p className='caption-partners'>{val.name}</p>
                                    <p className='caption-partners '>{val.country}</p>
                                    <p className='caption-partners flex items-center '><BsTelephone className='mr-[1vw]'/>{val.phone}</p>
                                    <p className='caption-partners flex items-center '><IoMailOutline className='mr-[1vw]'/>{val.email}</p>
                                </div>
                            </div>
                                </a>
                            </div>      
                        )
                        }
                    } else {
                        return null;
                    }
                })
            }
                </div>
                <div className=''>
            {
                data.map((val, items ) => {
                    if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                        if (val.id % 2 == 0) {
                        
                        return (
                            <div className='flex flex-row'>
                                <a href={val.path}>
                                <div key={items} className='flex flex-row caption-card box1 cursor-pointer w-[43vw] h-[38vh] border-2 bg-white mr-[1vw] mt-[5vh]'>
                                <img src={val.images} className='w-[20vw] h-full'></img>
                                <div className='pl-[2vw] pt-[5vh] justify-start'>
                                    <p className='caption-partners'>{val.name}</p>
                                    <p className='caption-partners '>{val.country}</p>
                                    <p className='caption-partners flex items-center '><BsTelephone className='mr-[1vw]'/>{val.phone}</p>
                                    <p className='caption-partners flex items-center '><IoMailOutline className='mr-[1vw]'/>{val.email}</p>
                                </div>
                            </div>
                                </a>
                            </div>      
                        )
                        }
                    } else {
                        return null;
                    }
                })
            }
                </div>
  
            </div>
           </section>
           <SubFooter/>
        </>
        
    );
}

export default Partners;