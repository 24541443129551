import React from 'react'
// import ButtonIcon from '../ButtonIcon'

const CardPathways = ({ title, description, onClick, style }) => {
  return (
    <div onClick={onClick} className="cursor-pointer rounded-xl w-[18vw] h-[16vh] mr-[3vw] mb-[10vh]">
        <h3 className='text-[1.5vw] mb-[5vh] tracking-wider'>{title}</h3>
      <div className={`flex h-full border-[#d2d2d2] border-[0.15vw]  rounded px-[2vw] py-[3vh] ${style}`}>
        <div className='flex text-center items-center justify-center m-0 w-[100%] '>
          <p className='text-[1vw] w-[90%] text-center'>{description}</p>  
      </div>
      <div className=''>
          {/* <button onClick={onClick} className='text-white border-[0.1vw] rounded px-[0.5vw] py-[0.5vh] border-white mb-[3vh]'>APPLY</button> */}
      </div>

     </div>
</div>
  )
}

export default CardPathways