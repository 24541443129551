import React from 'react';

import NavbarHome from '../../components/NavbarHome';
import { MdOutlineDateRange } from 'react-icons/md';
import { IoMailOutline } from 'react-icons/io5';
import SubFooter from '../../components/SubFooter';

const Publication = () => {
     const data = [
        {
            id:1,
            date: "08 April 2021",
            title: "Gojek fined rp 3.3 billion for late acquisition notification",
            category: "Knowledge Highlights",
        },
        {
            id:2,
            date: "16 Maret 2021",
            title: "S&T Partner Denia Isetianti Permata Recognised as ALB Indonesia Rising Star",
            category: "S&T News",
        },
        {
            id:3,
            date: "7 Maret 2021",
            title: "Against all odds: Soemadipradja & Taher helps secure a win for PT Astra Honda Motor in a competition complaint initiated by KPPU",
            category: "S&T News",
        },
        {
            id:2,
            date: "05 Februari 2021",
            title: "Guidelines on Mergers, Consolidations or Acquisitions Assessments Issued by Indonesia’s Competition Commission",
            category: "Knowledge Highlights",
        },
        {
            id:1,
            date: "08 April 2021",
            title: "Gojek fined rp 3.3 billion for late acquisition notification",
            category: "Knowledge Highlights",
        },
        {
            id:2,
            date: "16 Maret 2021",
            title: "S&T Partner Denia Isetianti Permata Recognised as ALB Indonesia Rising Star",
            category: "S&T News",
        },
        {
            id:3,
            date: "7 Maret 2021",
            title: "Against all odds: Soemadipradja & Taher helps secure a win for PT Astra Honda Motor in a competition complaint initiated by KPPU",
            category: "S&T News",
        },
        {
            id:2,
            date: "05 Februari 2021",
            title: "Guidelines on Mergers, Consolidations or Acquisitions Assessments Issued by Indonesia’s Competition Commission",
            category: "Knowledge Highlights",
        },
    ]


    const [filter, setFilter] = React.useState('all');
    const [filteredData, setFilteredData] = React.useState([]);
    
    React.useEffect(() => {
        filter === 'all' ? setFilteredData(data) : setFilteredData(data.filter(item => item.category === filter));
    }, [filter])   
    
    
    const [option, setOption] = React.useState('publication')

    const handleChange = (e) => {
        setOption(e.target.value)
    }
    

    
    return (
        <>
        <NavbarHome />
        <section className='items-start pages'>
    
        <div className='flex flex-col pl-[5vw] justify-between pt-[5vh] '>
            <div className='flex flex-col'>
                <ul className='flex flex-row justify-between'>
                  <h1 className='caption-home1 text-[#4493f1]'>PUBLICATION</h1>
                  <select
                  value={option}
                    onChange={handleChange}
                  className="border-b-[1px] block caption-partners2 w-[15vw] h-[6vh] px-[0.5vw] py-[0.3vw]" >
                    <option value='publication'
                    className='py-[2vh]'>PUBLICATION</option>
                    <option value='perspectives'
                    className='py-[2vh]'>PERSPECTIVES</option>
                </select>
                
                
                </ul>
                <ul className='flex flex-row pb-[5vh] text-[#123872]'>
                  <button 
                  onClick={() => setFilter('Knowledge Highlights')}
                  className="flex justify-center items-center border-[0.1vw] focus:bg-[#4493f1] focus:text-white mr-[2vw] w-[15vw] h-[6vh] border-[#4493f1] hover:bg-[#4493f1] rounded text-[1vw] hover:text-white text-[#4493f1]">
                        KNOWLEDGE UPDATE
                    </button>
                  <button 
                    onClick={() => setFilter('S&T News')}
                  className="flex justify-center items-center border-[0.1vw] focus:bg-[#4493f1] focus:text-white mr-[2vw] w-[15vw] h-[6vh] border-[#4493f1] hover:bg-[#4493f1] rounded text-[1vw] hover:text-white text-[#4493f1]">
                        PUBLISH ARTICLE
                    </button>
                  <a href='#' className="flex items-center border-[0.1vw] mr-[2vw] w-[15vw] focus:bg-[#4493f1] focus:text-white h-[6vh] px-[1.5vw] border-[#4493f1] hover:bg-[#4493f1] rounded text-[1vw] hover:text-white text-[#4493f1]"><button className="flex flex-row items-center">
                        <MdOutlineDateRange className='mr-[0.5vw]'/>2021
                    </button></a>
                    <a href='mailing-list' className="flex justify-center items-center border-[0.1vw] focus:bg-[#4493f1] focus:text-white mr-[2vw] w-[15vw] h-[6vh] border-[#4493f1] hover:bg-[#4493f1] rounded text-[1vw] hover:text-white text-[#4493f1]"><button className="flex flex-row items-center">
                        <IoMailOutline className='mr-[0.5vw]'/>JOIN OUR MAILING LIST
                    </button></a>
                </ul>    
            </div>

            <ul className='w-[65vw]'>
                    <h3 className='caption-home-sub'>OUR PUBLICATIONS</h3>
                    <p className='caption-home-sub2'>As part of our learing and knowledge-sharing culture, we encourge you to explore our onlibe libabry of thought leadership. Our publication will keep you appriesed of the latest legal and regulatory developments imapcting businesses and enterprises. </p>
            </ul>
            <div className='flex flex-row mt-[5vh]'>
            <div className="rounded-2xl w-[35vw] h-[30vh] mr-[1vw] mb-[5vh]">
                <div className="flex flex-col h-full bg-[#4493f1] rounded px-[2vw] pt-[5vh]">
                    <div className='text-start text-white m-0 w-[100%] '>
                    <h3 className='text-[1.2vw] mb-[3vh] tracking-wider'>Knowledge Updates</h3>
                    <p className='caption-home-sub2 '>Our Knowledge Updates cover key legal and regulatory development across parcatice area in Indonesia</p>  
                </div>
                </div>
            </div>
            <div className="rounded-2xl w-[35vw] h-[30vh] mr-[1vw] mb-[5vh]">
                <div className="flex flex-col h-full bg-[#4493f1] rounded px-[2vw] pt-[5vh]">
                    <div className='text-start text-white m-0 w-[100%] '>
                    <h3 className='text-[1.2vw] mb-[3vh] tracking-wider'>Published Articles</h3>
                    <p className='caption-home-sub2 '>A collection of our lawyers externally published article</p>  
                </div>
                </div>
            </div>
            </div>
            <div className='border-[0.1vw] border-black w-[85vw]'></div>


            <div className='flex flex-col'>
            {
                filteredData.map((item) => {
                    return (
                        <div className="mt-[5vh] border-[0.1vw] rounded-xl border-[#f4f4f4] w-[85vw]">
                            <div className="flex flex-col h-full uppercase rounded px-[2vw] pt-[2vh]">
                                <div className='text-start text-black m-0 w-[100%] '>
                                <h3 className='text-[1.2vw] mb-[1vh] tracking-wider'>{item.category} | {item.date}</h3>
                                <h2 className='caption-home-sub2 text-[#4493f1]'>{item.title}</h2>  
                            </div>
                            </div>
                        </div>
                    )
                })
            }
            </div>
            <div className='my-[10vh] ml-[2vw]'>
                <button className='mr-[4vw] text-[#4493f1] btn5 active focus caption-home-sub2'>1</button>
                <button className='mr-[4vw] text-[#4493f1] btn5 caption-home-sub2'>2</button>
                <button className='mr-[4vw] text-[#4493f1] btn5 caption-home-sub2'>3</button>
                <button className='mr-[4vw] text-[#4493f1] btn5 caption-home-sub2'>4</button>
                <button className='mr-[4vw] text-[#4493f1] btn5 caption-home-sub2'>5</button>
            </div>
        </div>        
    </section>

        <SubFooter/>
        </>
        
    );
}

export default Publication;