import React from 'react'
// import ButtonIcon from '../ButtonIcon'

const CardPathways = ({ title, description, onClick, style }) => {
  return (
    <div className="rounded-2xl w-[44vw] h-[35vh] mr-[1vw] mb-[5vh]">
      <div className="flex flex-col place-content-between h-full bg-[#4493f1] rounded-xl px-[2vw] py-[3vh]">
        <div className='text-start text-white m-0 w-[100%] '>
          <h3 className='text-[1.5vw] mb-[3vh] tracking-wider'>{title}</h3>
          <p className='text-normal w-[80%]'>{description}</p>  
      </div>
      <div className=''>
          <button onClick={onClick} className='text-white btn4 mb-[3vh]'>APPLY</button>
      </div>

     </div>
</div>
  )
}

export default CardPathways