import React from 'react';
import NavbarSidebar from '../../components/NavbarSidebar';

const MailingList = () => {
    return (
        <>
        <NavbarSidebar />
        <section className='items-start pl-[5vw] mt-[8vh]'>
        <div className='flex flex-row justify-center'>
        
        </div>
        
        <div className='w-[88vw]'>
            <h2 className='font-medium text-[1.2rem] text-[#4493f1] pb-[3vh]'>Join our mailing list</h2>
            <h2 className='text-black font-normal pb-[3vh]'>If you wish to be on our mailing list to receive electronic communications (including bulletins, updates, alerts, reviews, invitations and announcements) from Soemadipradja & Taher, please complete the form below.</h2>
            <div className='mt-[5vh]'>
                <h2 className='caption-form text-[#4493f1] font-medium'>contact details</h2>
            </div>    
        </div>

        <section className="pt-[2vh] pb-[5vw] flex items-center w-[95%]">
            <form className='flex flex-col flex-wrap justify-between'>
                <div className='flex flex-row justify-between w-[88vw]'>
                    <div className='flex flex-col'>
                        <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1] font-medium'>salutation *</p>
                        <select className="border-[1px] block w-[25vw] px-[0.5vw] py-[0.3vw] rounded" >
                            <option>Please Select</option>
                            <option value="Mr">Mr</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Ms">Ms</option>
                            <option value="Mdm">Mdm</option>
                            <option value="Dr">Dr</option>
                        </select>
                        </div>
                    <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]'>first name *</p>
                    <input type="text" className="border-[1px] block w-[25vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>last name *</p>
                    <input type="text" className="border-[1px] block w-[25vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>designation *</p>
                    <input type="text" className="border-[1px] block w-[88vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>company name *</p>
                    <input type="text" className="border-[1px] block w-[88vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>business email address *</p>
                    <input type="email" className="border-[1px] block w-[88vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                <div className='flex flex-row justify-between w-[88vw]'>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]'>direct telephone number *</p>
                    <input type="text" className="border-[1px] block w-[42vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>business mobile number *</p>
                    <input type="text" className="border-[1px] block w-[42vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>business address *</p>
                    <input type="text" className="border-[1px] block w-[88vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>business postal code *</p>
                    <input type="text" className="border-[1px] block w-[88vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>country of business address *</p>
                    <input type="text" className="border-[1px] block w-[88vw] px-[0.5vw] py-[0.3vw] rounded required" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>industry sector (you may select more than one) *</p>
                    <div className='flex flex-row w-[70vw] justify-between'>
                    <div className='flex flex-col'>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Aerospace & Defence</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Aviation & Maritime</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Banks & Financial Institutions</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Chemicals, Energy & Utilities</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Conglomerate</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Consumer & Retail</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>E-Commerce</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Electronic & Engineering</label>
                        </div>
                    </div>
                        <div className='flex flex-col'>
                            <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Fintech</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Food & Beverage</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Funds & Investment Management</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Goverment Institutions</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Healthcare & Pharma</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Hotels & Leisure</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Infrastructure & Construction</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Insurance</label>
                        </div>
                    </div>
                </div>
                <div>
                <div className='flex flex-col mt-[2vw]'>
                            <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Manufacturing</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Media & Entertainment</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Natural Resources</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Non-Profit Organisations (Including Charities)</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Professional Services (Including Legal Services)</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Real Estate & REITs</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Technology</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Telecommunications</label>
                        </div>
                    </div>
                </div>
                    
                    </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>other (please specify) *</p>
                    <input type="email" className="border-[1px] block w-[88vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                
                <div className='flex flex-col'>
                    <p className='caption-form mb-[2vh] mt-[8vh] text-[#4492f1] font-medium '>subscription preferences</p>
                    <p className='caption-form mb-[2vh] mt-[8vh] text-[#4492f1] font-medium '>area of interest</p>
                    <div className='flex flex-row w-[70vw] justify-between'>
                    <div className='flex flex-col'>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Banking & Finance</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Capital Markets</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Competition & Antitrust</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Corporate & Commercial</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Corporate Real Estate</label>
                        </div>
                        
                    </div>
                        <div className='flex flex-col'>          
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Employment, Health & Safety</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Energy, Infrastructure & Projects</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>FinTech</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Intellectual Property</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>International Arbitration</label>
                        </div>
                    </div>
                    <div className='flex flex-col'>          
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Litigation</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Maritime & Aviation</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Mergers & Acquisitions</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Restructuring & Insolvency</label>
                        </div>
                        <div className="mb-[3vh]">
                            <input type="checkbox" id="aerospace" value=""></input>
                            <label for="" className='ml-[1vw] caption-form'>Technology, Media & Telecommunications</label>
                        </div>
                    </div>
                </div>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>other (please specify) *</p>
                    <input type="email" className="border-[1px] block w-[44vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                <div className='flex flex-col font-medium'>
                    <p className='caption-form mb-[2vh] mt-[4vh] text-[#4492f1]  '>HAVE YOU SIGNED UP FOR OUR MAILING LIST PREVIOSLY</p>
                    <input type="email" className="border-[1px] block w-[44vw] px-[0.5vw] py-[0.3vw] rounded" ></input>
                </div>
                <p className='mt-[8vh]'>Please note that, by sending this completed form, you have agreed to receive electronic communications from Soemadipradja & Taher, and allow us to use your personal data to send these communications to you. Should you need any clarification, please do not hesitate to contact us at +62 50 999 879 or enquiries@soemath.com. We thank you for sending in your completed subscription form.</p>
            </form>
        </section>
        <a>
                <button className='mb-[10vh] px-[1vw] py-[1vh] bg-[#4493f1] rounded hover:bg-[#123872]'>
                    <p className='text-white font-normal'>Submit</p>
                </button>
            </a>

    </section>
        </>
        
    );
}

export default MailingList;