import React from 'react';
import keycontact from "../../../assets/images/keycontact.png"
import NavbarSidebar from '../../../components/NavbarSidebar';
import { BsArrowRightShort } from 'react-icons/bs';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi'
import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

import DIP from "../../../assets/images/Partners/DIP.jpg"
import EA from "../../../assets/images/Partners/EA.jpg"
import RAD from "../../../assets/images/Partners/RAD.jpg"
import RR from "../../../assets/images/Counsel/RR.jpg"
import OJM from "../../../assets/images/Counsel/OJM.jpg"
import SubFooter from '../../../components/SubFooter';

const Home = () => {
    return (
        <>
        <NavbarSidebar />
           <section className='pages pb-[15vh] pl-[5vw]'>
            <div className='flex flex-row justify-between pt-[5vh] w-[90vw]'>
                <div className='flex flex-col'>
                        <h3 className='flex flex-row text-[1.1vw] text-[#123872]'>
                        <HiOutlineArrowNarrowLeft className='mx-[0.5vw] mt-[0.5vh]'/>
                            <a href='/practice' className='mr-[5vw]'>
                                BACK TO PRACTICE AREA </a>
                            <HiOutlineArrowNarrowRight className='mx-[0.5vw] mt-[0.5vh]'/>NEXT
                        </h3>
                    <ul className='w-[95%]'>
                        <h1 className='caption-home1 text-[#123872]'>BANKING & FINANCE</h1>
                        <p className='caption-home-sub2 mb-[5vh]'>Responding to change</p>
                        <p className='caption-home-sub2 mb-[5vh]'>Over the past two decades, the Indonesian banking and financial services industries have constantly experienced change brought about by increased regulation in response to the Asian Financial Crisis in 1997, the emergence of new local and international players and the ongoing development of new technology in the field. Keeping pace with this sector and providing the most trusted and innovative response to its evolving needs demands strong resources and the requisite skills, knowledge, insight and experience.
                        </p>
                        <p className='caption-home-sub2 mb-[5vh]'>Working with banking and finance</p>
                        <p className='caption-home-sub2 mb-[5vh]'>Our Banking & Finance practice has extensive experience in handling Indonesian, international and multinational clients on a wide spectrum of local and cross-border banking and financial service matters, including asset finance; project finance; securitization; derivatives; structured finance; and financial services regulatory and advisory. Our team is also highly capable of advising on a full range of financial transactions, from simple loan agreements to complicated cross-border secured and syndicated loans, highly structured debt financings and financial restructurings.</p>
                        <p className='caption-home-sub2 mb-[5vh]'>Serving with distinction</p>
                        <p className='caption-home-sub2 mb-[5vh]'>Our team is regularly involved in cross-border transactions and significant matters at a domestic, regional and international level, and continuously strives to provide comprehensive and practical solutions tailored to our clients’ unique requirements and circumstances. This exposure has allowed the team to develop a high degree of sensitivity to the norms, cultures and standards of our local and international clients. As part of the Allen & Gledhill network, we leverage a history of working with the banking and finance community to offer perspectives and insights based on our pioneering and present work, and are proud to continue a long tradition of serving our clients with distinction.</p>
                        </ul>
                </div>
            </div>
            <h1 className='caption-home1 text-[#123872]'>KEY CONTACT</h1>
            <div className='mb-[15vh] flex flex-row'>
                <div className='mr-[4vw]'> 
                <img src={DIP} className='mb-[4vh] h-[25vh] w-[20vw] cursor-pointer'></img>
                <p className='caption-home-sub3'><u>Denia Isetianti Permata</u></p>
                <p className='caption-home-sub3'>Indonesia</p>
                <p className='caption-home-sub3'>(62-21) 50 999 879 (ext. 1919)</p>
                <p className='caption-home-sub3'><u>denia_isetianti@soemath.com</u></p>
                </div>
                <div className='mr-[4vw]'> 
                <img src={EA} className='mb-[4vh] h-[25vh] w-[20vw] cursor-pointer'></img>
                <p className='caption-home-sub3'><u>Emalia Achmadi</u></p>
                <p className='caption-home-sub3'>Indonesia</p>
                <p className='caption-home-sub3'>(62-21) 50 999 879 (ext. 1906)</p>
                <p className='caption-home-sub3'><u>emalia_achmadi@soemath.com</u></p>
                </div>
                <div className='mr-[4vw]'> 
                <img src={OJM} className='mb-[4vh] h-[25vh] w-[20vw] cursor-pointer'></img>
                <p className='caption-home-sub3'><u>Oene Marseille</u></p>
                <p className='caption-home-sub3'>Indonesia</p>
                <p className='caption-home-sub3'>(62-21) 50 999 879 (ext. 1923)</p>
                <p className='caption-home-sub3'><u>oene_marseille@soemath.com</u></p>
                </div>
                <div className='mr-[4vw]'> 
                <img src={RAD} className='mb-[4vh] h-[25vh] w-[20vw] cursor-pointer'></img>
                <p className='caption-home-sub3'><u>Retno Muljosantoso</u></p>
                <p className='caption-home-sub3'>Indonesia</p>
                <p className='caption-home-sub3'>(62-21) 50 999 879 (ext. 1901)</p>
                <p className='caption-home-sub3'><u>r_muljosantoso@soemath.com</u></p>
                </div>
                <div className='mr-[4vw]'> 
                <img src={RR} className='mb-[4vh] h-[25vh] w-[20vw] cursor-pointer'></img>
                <p className='caption-home-sub3'><u>Robert Reid</u></p>
                <p className='caption-home-sub3'>Indonesia</p>
                <p className='caption-home-sub3'>(62-21) 50 999 879 (ext. 1904)</p>
                <p className='caption-home-sub3'><u>robert_reid@soemath.com</u></p>
                </div>
            </div>
    </section>
    <SubFooter/>
        </>
        
    );
}

export default Home;