import React from 'react';
import Ornament76 from "../../assets/images/Ornament-76.svg";
import Ornament75 from "../../assets/images/Ornament-75.svg";

import NavbarSidebar from '../../components/NavbarSidebar';
import SubFooter from '../../components/SubFooter';
import CardPractice from '../../components/CardPractice';
import { BsArrowRight } from 'react-icons/bs';
import { RiArrowDropDownLine } from 'react-icons/ri';
import { BsChevronDown } from 'react-icons/bs';
import { BsArrowRightShort } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';

const Practice = () => {
  const navigate = useNavigate();
    return (
        <>
        <NavbarSidebar />
           <section className='pages pb-[18vh]'>
            <div className='flex flex-row pl-[5vw] justify-between pt-[5vh]'>
                <div className='flex flex-col'>
                        <h3 className='flex flex-row text-[1.1vw] text-[#123872]'><a href='/home'>HOME </a><BsArrowRightShort className='mx-[0.5vw] mt-[0.5vh]'/>PRACTICE</h3>
                    <ul className='w-[95%]'>
                        <h1 className='caption-home1 text-[#4493f1]'>Term Of Use</h1>
                        <p className='caption-home-sub2 '>Our range of practices are populated with seasoned practitioners ready to provide incisive expert knowledge and opinions on any particular matter. We offer a comprehensive suite of legal services delivered by a bench of lawyers with wide experience and deep expertise in their fields
                        <p>.</p>
                        </p>
                        <p className='caption-home-sub2'>Moreover, we recognise that the legal issues of today are oftentimes multi-faceted and complex, cutting across various legal disciplines and jurisdictions. In this regard, as specialists in diverse practice areas, we regularly work together in multi-disciplinary teams across our offices and network, mindful of the need to meet our clients’ expectations and to deliver a seamless and practicable solution, no matter how complicated the tasks or issues involved.</p>
                    </ul>
                </div>
            </div>

        <section id="practice" className='mt-[5vh] mx-[20vw] justify-center items-center'>
        <div>
            <img src={Ornament75} className='absolute left-0 mt-[10vh] w-[10vw]'></img>
        </div>
        <div>
        <img src={Ornament76} className='absolute right-0 bottom-[-150vh] w-[15vw]'></img>
        
        </div>
        <div className="flex flex-row items-center justify-center mb-[5vh]">
          <CardPractice
            title='1'
            description='BANKING & FINANCE'
            onClick={() => navigate('/practice/banking-and-finance')}
            style='one'
            
            />
          <CardPractice
            title='2'
            description='CAPITAL MARKETS'
            style='two'
          />
          <CardPractice
            title='3'
            description='COMPETITION & ANTITRUST'
            style='three'
          />
        </div>
        <div className="flex flex-row mb-[5vh]"> 
          <CardPractice
            title='4'
            description='CORPORATE & COMMERCIAL'
            style='four'
          />
          <CardPractice
            title='5'
            description='CORPORATE REAL ESTATE'
            style='five'
          />
          <CardPractice
            title='6'
            description='EMPLOYMENT, HEALTH & SAFETY'
            style='six'
          />
        </div>
        <div className="flex flex-row mb-[5vh]"> 
          <CardPractice
            title='7'
            description='ENERGY, INFRASTRUCTURE & PROJECTS'
            style='seven'
          />
          <CardPractice
            title='8'
            description='FINTECH'
            style='eight'
          />
          <CardPractice
            title='9'
            description='INTELLLECTUAL PROPERTY'
            style='nine'
          />
        </div>
        <div className="flex flex-row mb-[5vh]"> 
          <CardPractice
            title='10'
            description='INTERNATIONAL ARBITRATION'
            style='ten'
          />
          <CardPractice
            title='11'
            description='LITIGATION'
            style='eleven'
          />
          <CardPractice
            title='12'
            description='MARITIME & AVIATION'
            style='twelve'
          />
        </div>
        <div className="flex flex-row mb-[5vh]"> 
          <CardPractice
            title='13'
            description='MERGERS & ACQUISITIONS'
            style='thirteen'
          />
          <CardPractice
            title='14'
            description='RESTRUCTURING & INSOLVENCY'
            style='fourteen'
          />
          <CardPractice
            title='15'
            description='TECHNOLOGY, MEDIA & TELECOMMUNICATIONS'
            style='fifteen'
          />
        </div>

      </section>
    </section>

    <SubFooter />
        </>
        
    );
}

export default Practice;