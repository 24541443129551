import React from 'react';
import { useState } from 'react';
import rectangle from "../../../assets/images/Rectangle-39.svg";
import { BsChevronDown } from 'react-icons/bs';

import ones from "../../../assets/images/accolades/2021-1.jpg";
import twos from "../../../assets/images/accolades/2020-1.jpg";
import threes from "../../../assets/images/accolades/2020-2.jpg";
import four from "../../../assets/images/accolades/2020-3.jpg";
import five from "../../../assets/images/accolades/2019-1.jpg";
import six from "../../../assets/images/accolades/2019-2.jpg";
import seven from "../../../assets/images/accolades/2018-1.jpg";
import eight from "../../../assets/images/accolades/2018-2.jpg";
import nine from "../../../assets/images/accolades/2018-3.jpg";
import ten from "../../../assets/images/accolades/2016-1.jpg";
import eleven from "../../../assets/images/accolades/2016-2.jpg";
import twelve from "../../../assets/images/accolades/2016-3.jpg";
import thirteen from "../../../assets/images/accolades/2016-4.jpg";
import fourteen from "../../../assets/images/accolades/2016-5.jpg";
import fifteen from "../../../assets/images/accolades/2016-6.jpg";
import sixteen from "../../../assets/images/accolades/2016-7.jpg";
import seventeen from "../../../assets/images/accolades/2015-1.jpg";
import eighteen from "../../../assets/images/accolades/2015-2.jpg";
import nineteen from "../../../assets/images/accolades/2015-3.jpg";
import twenty from "../../../assets/images/accolades/2014-1.jpg";
import twentyone from "../../../assets/images/accolades/2014-2.jpg";
import twentytwo from "../../../assets/images/accolades/2014-3.jpg";
import twentythree from "../../../assets/images/accolades/2014-4.jpg";
const Accolades = () => {
    const [show, setShow] = React.useState(false);
    const [showOne, setShowOne] = React.useState(false);
    const [showTwo, setShowTwo] = React.useState(false);
    const [one, setOne] = useState([
        {
            name: 'Chambers Asia-Pacific Leading Firm 2021',
            value: 'Soemadipradja & Taher maintained as Chambers Asia-Pacific Leading Firm 2021. Consistently ranked top-tier by legal directories, S&T has advised on some of Indonesia’s most significant and complex transactions, providing domestic, foreign and multinational clients. S&T has extensive market knowledge, which coupled with a solutions-focused approach, offers an unrivalled breadth and depth of expertise. For more information about S&T in Chambers Asia, please click here' 
        }
    ])

    const [two, setTwo] = useState([
        {
            name: 'The Legal 500 Top Tier 2020',
            value: 'Soemadipradja & Taher has been ranked as a Top Tier Firm in The Legal 500 Asia Pacific 2021. The Legal 500 is a leading independent legal directory that provides comprehensive and objective analysis of law firms and lawyers across the globe. The Legal 500 Asia Pacific 2021 is the most comprehensive guide to the best law firms and lawyers in the region. For more information about S&T in The Legal 500, please click here'
        }
    ])

    const [three, setThree] = useState([
        {
            name: '',
            value: 'Soemadipradja & Taher has been ranked as a Tier 1 Firm in IFLR1000 2021. IFLR1000 is the leading guide to the world’s top financial and corporate law firms and lawyers. IFLR1000 is the most comprehensive guide to the best law firms and lawyers in the region. For more information about S&T in IFLR1000, please click here'
        }
    ])

    const handleRotate = () => setShow(!show);
    const rotate = show ? "rotate(180deg)" : "rotate(0)"

    const handleRotateOne = () => setShowOne(!showOne);
    const rotateOne = showOne ? "rotate(180deg)" : "rotate(0)"
    
    
        const [selectedOne, setSelectedOne] = useState(null)
    return (
        <>
        <div className=''>
            <ul className=''>
                    <h1 className='caption-home'>ACCOLADES</h1>
                    <p className='caption-home-sub2'>We seek the highest standards for our clients. Our prime focus has been, and remains, harnessing strong legal expertise, commercial acumen and practical experience to deliver the best outcomes for our clients.</p>
                    
                    <p className='caption-home-sub2'>Through the years, our approach and commitment to the practice of law have attracted recognition and honours. We are deeply grateful for the trust and confidence that our clients place in us, and for the many awards and accolades accorded to us.</p>

                <div className='overflow-y-auto h-[80vh]'>
                    <div className='flex flex-col border-b-2 pb-[4vh]'>
                        <p className='year'>2021</p>
                        <div className='flex flex-row justify-between items-start mb-[5vh]'
                        onClick={e => {
                            handleRotate()}} >
                            <div className='flex flex-row cursor-pointer caption-home-sub2'>
                            <img src={ones} className="w-[5vw] mr-[1vw]"></img>
                            {
                            selectedOne != null ? one[selectedOne].name : "Chambers Asia-Pacific Leading Firm 2021" 
                            }
                            </div>
                            <BsChevronDown className='w-[2vw] mr-[2vw]' style={{ transform: rotate, transition: "all 0.5s linear" }}/>
                    </div>
                    {
                show ? (
                    <div className='mb-[6vh] ml-[1vw] caption-home-sub2 w-[90%]'>
                {one.map((item, index) => (
                    <div key={item.value} >
                        {item.value}
                    </div>
                ))
                }
            </div>
                ) : <></>
            }
                
            
            </div>
                
                    <div className='flex flex-col border-b-2'>
                        <p className='year'>2020</p>
                        <div className='flex flex-row justify-between items-start mb-[4vh]'
                    onClick={e => {
                    handleRotateOne()}} >
                        <div className='flex flex-row cursor-pointer caption-home-sub2'>
                            <img src={twos} className="w-[5vw] mr-[1vw]"></img>
                            {selectedOne != null ? one[selectedOne].name : "The Legal 500 Top Tier 2020" }
                        </div>
                        <BsChevronDown className='w-[2vw] mr-[2vw]' style={{ transform: rotateOne, transition: "all 0.5s linear" }}/>
                    </div>
                    {
                showOne ? (
                    <div className='mb-[6vh] ml-[1vw] caption-home-sub2 w-[90%]'>
                {two.map((item, index) => (
                    <div key={item.value} >
                        {item.value}
                    </div>
                ))
                }
            </div>
                ) : <></>
            }
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={threes} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>IFLR 1000 Recommended Firm 2020</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={four} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>ALB M&A Rankings 2020</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                    </div>
                    <div className='flex flex-col border-b-2'>
                        <p className='year'>2019</p>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={five } className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>ALB M&A Rankings 2019</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={six } className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>Chambers Asia-Pacific Leading Firm 2019</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                    </div>
                    <div className='flex flex-col border-b-2'>
                        <p className='year'>2018</p>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={seven} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>Asialaw Outstanding Firm 2018</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={eight} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>Chambers Asia-Pacific Leading Firm 2018</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={nine} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>The Legal 500 Leading Firm 2018</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                    </div>
                    <div className='flex flex-col border-b-2'>
                        <p className='year'>2016</p>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={ten} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>Top Ranked in Chambers Asia Pacific 2016</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={eleven} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>Asialaw Highly Recommended Firm 2016</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={twelve} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>IFLR Financial & Corporate Recommended Firm 2016</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={thirteen} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>The Legal 500 Leading Firm 2016</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={fourteen} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>The Legal 500 Leading Individual 2016</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={fifteen} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>The Legal 500 Recommended Lawyer 2016</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={sixteen} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>The Legal 500 Asia Pacific Top Tier 2016</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                    </div>
                    <div className='flex flex-col border-b-2'>
                        <p className='year'>2015</p>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={seventeen} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>Asialaw Highly Recommended Firm 2015</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={eighteen} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>IFLR Financial & Corporate Recommended Firm 2015</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={nineteen} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>Ranked in Chambers Asia Pacific 2015</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                    </div>
                    <div className='flex flex-col border-b-2'>
                        <p className='year'>2014</p>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={twenty} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>Asialaw Highly Recommended Firm 2014</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={twentyone} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>IFLR Energy & Infrastructure Highly Recommended Firm 2014</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={twentytwo} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>The Winner of ALB Deal of the Year Awards 2014</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                        <div className='flex flex-row items-start justify-between mb-[8vh]'>
                            <div className='flex flex-row cursor-pointer'>
                                <img src={twentythree} className="w-[5vw]"></img>
                            <p className='caption-home-sub2 px-[1vw]'>IFLR Financial & Corporate Recommended Firm 2014</p>
                           </div> <BsChevronDown className='w-[2vw] mr-[2vw]'/>
                        </div>
                    </div>
                    </div>
                </ul>
                </div>
        </>
        
    );
}

export default Accolades;