import React from "react";
import { FiSearch } from "react-icons/fi";

import logo from "../../assets/images/LOGO.png";
const Navbar = () => {
    return (
        <section className="flex flex-row justify-between items-center  bg-white h-[10vh] px-[5vw] py-[2vh]">
            <div className="logo">
                <a href="#">
                    <img src={logo} alt="logo" className="w-[20vw]"/>
                </a>
            </div>
            <div>
                <ul className="">
                    <li>
                        <a href="#" className=""><FiSearch className="icon"/></a>
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default Navbar;