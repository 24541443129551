import React from 'react';
import { useNavigate } from 'react-router-dom';
// import shape from "../../assets/images/Frame.svg";

import Navbar from '../../components/Navbar';
import { RxDoubleArrowRight } from 'react-icons/rx';
const Main = () => {
    const navigate = useNavigate();

    return ( 
        <>
        <Navbar />
        <section className='main px-[6vw] py-[20vh]'>
            <div className="flex relative">
                <h1 className='caption w-[70%]'>COMMITMENT, UNDERSTANDING, TRUST</h1>
            </div>

            <button 
                className="relative flex items-center justify-center overflow-hidden transition duration-300 ease-out btn group"
                onClick={() => navigate('/home')}
                >
                <span className='absolute flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-black group-hover:translate-x-0 ease'>ENTER OUR WEBSITE<RxDoubleArrowRight className='ml-[1vh]'/></span>
                    <span className='absolute flex items-center justify-center transition-all duration-300 transform group-hover:translate-x-full ease'>ENTER OUR WEBSITE</span>
            </button>
        </section>
        {/* <img src={shape} className="absolute z-0 top-[10vh] w-[100%]"></img> */}
        </> 
        
    );
}

export default Main;