import NavbarSideBar from '../../components/NavbarSidebar'
import SubFooter from '../../components/SubFooter'

const TermOfUse = () => {
    return (
        <>
        <NavbarSideBar/>
        <section className='pages pb-[30vh]'>
            <div className='flex flex-row pl-[5vw] justify-between pt-[5vh] '>
                <div className='flex flex-col'>
                    <ul className='w-[95%]'>
                        <h1 className='caption-home1 text-[#4493f1]'>Practice</h1>
                        <p className='caption-home-sub3 mb-[3vh] '>By using or accessing the website at <a href='http://www.soemath.com'><u>http://www.soemath.com</u></a> ("Website") you agree to be bound by the following terms and conditions, as they may be amended from time to time.</p>
                        <p className='caption-home-sub3 mb-[3vh]' >The Website and all web pages thereunder, including the information, content, graphics and other materials displayed therein and any functionality or facility provided therein (collectively "Content") are provided on an "as is" and "as available" basis for general information purposes and not as legal or other professional advice. No warranties of any kind are given in respect of the Website or the Content.</p>
                        <p className='caption-home-sub3 mb-[3vh] '>No part of the Website or Content may be reproduced, distributed, mirrored or framed, save that Content may be downloaded, printed or used for personal and non-commercial purposes.
                            Content may be modified, suspended or removed without prior notice.</p>
                        <p className='caption-home-sub3 mb-[3vh] '>These terms and conditions shall be governed by and construed in accordance with Indonesian law.</p>
                    </ul>
                </div>
            </div>
        </section>
        <SubFooter/>
        </>
    )
}

export default TermOfUse